import { Stack } from "@mui/material";
import React from "react";

export default function Partners() {
  const partnersInformations = [
    {
      name: "Tamer Capital",
      image: "./images/tamerCapital.png",
    },
    {
      name: "Turk Telekom Ventures",
      image: "./images/turkTelekomVentures.png",
    },
    {
      name: "Pilot",
      image: "./images/pilot.png",
    },
    {
      name: "Tubitak Martek",
      image: "./images/tubitak.png",
    },
    {
      name: "Tevetoglu Legal",
      image: "./images/tevetoglu.png",
    },
    {
      name: "Etasports",
      image: "./images/etasports.png",
    },
    {
      name: "Eth",
      image: "./images/eth.png",
    },
    {
      name: "Metamask",
      image: "./images/metamask.png",
    },
    {
      name: "Partner",
      image: "./images/partnerLogo.png",
    },
  ];

  return (
    <div className="container px-[45px]" id="partners">
      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          columnGap: "90px",
          rowGap: "30px",
          ["@media (max-width:1024px)"]: {
            columnGap: "10px",
            rowGap: "10px",
          },
        }}
      >
        {partnersInformations.map(({ name, image }) => (
          <img
            src={image}
            alt={name}
            key={name}
            className="w-[150px] h-[150px] cursor-pointer bg-blend-luminosity opacity-50 grayscale-[1] hover:bg-blend-normal hover:opacity-100 hover:grayscale-0 lgx:w-[115px] lgx:h-[115px]"
          />
        ))}
      </Stack>
    </div>
  );
}
