import { useEffect } from "react";
import Layout from "../components/composite/Layout";

export default function CookiesPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div
        className="shadow-2xl  mx-[auto] my-[5%] p-6 rounded-md lgx:mx-[5%]"
        style={{
          fontFamily: "Poppins",
          maxWidth: 800,
        }}
      >
        <h1 className=" font-semibold text-[18px] text-[rgba(45,51,57,0.9)] mb-[15px] mt-[2px]">
          DEFİMEC ÇEREZ POLİTİKASI{" "}
        </h1>
        <div className=" text-[rgba(45,51,57,0.9)] text-[13px]">
          Defimec Blokzincir Teknolojileri Yazılım ve Sanayi Ticaret Anonim
          Şirketi (“Defimec”) tarafından sağlanan hizmetlerden en verimli
          şekilde faydalanabilmeniz ve kullanıcı deneyiminizi geliştirilebilmesi
          için çerez ve benzeri diğer bazı teknolojileri, ürünleri (birlikte
          “Çerez/Çerezler”) kullanılmaktadır.  Bu metinde, Defimec web
          sitelerini ziyaret etmeniz halinde kullanılacak Çerez’lere ilişkin
          açıklamalar yer almaktadır. 1. Çerez ve Diğer Benzer Teknolojiler
          Nedir?  Çerez, bir web sitesini ziyaret ettiğinizde tarayıcınız
          aracılığıyla bilgisayarınıza veya mobil cihazınıza kaydedilen küçük
          boyutlu bir metin dosyasıdır. Çerezler bir sitenin daha verimli
          çalışmasının yanı sıra kişisel ihtiyaçlarınıza daha uygun ve hızlı bir
          ziyaret deneyimi yaşatmak için kişiselleştirilmiş sayfaların
          sunulabilmesine olanak vermektedir. Çerezler, sadece internet
          ortamındaki ziyaret geçmişinize dair bilgiler içermekte olup
          bilgisayarınızda veya mobil cihazınızda depolanmış dosyalara dair
          herhangi bir bilgi toplamamaktadır. Bunun dışında Çerezlerle benzer
          amaçlarla kullanılan aşağıdaki bazı teknolojiler (“Çerez Benzeri
          Teknolojiler”) de bulunmaktadır.  Piksel etiketleri (bir web sitesine
          yerleştirilen ve bir sayfanın görüntülendiğini gösteren şeffaf
          grafiksel imgeler), Mobil cihaz tanımlayıcılar (IDFA, advertising ID
          vb. aracılığıyla bir mobil cihazın kullanımına ilişkin bilgilerin
          3.kişi ortamlarda depolanması),  Segmentasyon/Raporlama Ürünleri (web
          sitesi veya mobil cihazların kodlara çeşitli yöntemlerle eklenen bazı
          hazır kodlar aracılığıyla çalışan ve bu kodlar sayesinde
          kullanıcıların web sitesi veya mobil cihazdaki hareketlerini
          raporlayan/anlamlandıran/depolayan programlar/ürünler)  Bu metinde
          kullanılan Çerez/Çerezler ibaresi Çerez Benzeri Teknolojileri de
          kapsayacak şekilde kullanılacaktır.  2. Çerez Türleri ve Kullanım
          Amaçları 2.1. Kullanım süreleri bakımından çerezler Geçici Çerezler:
          Oturum Çerezleri ziyaretçilerin internet sitesini ziyaretleri
          süresince kullanılan, tarayıcı kapatıldıktan sonra sona eren geçici
          çerezlerdir. Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz
          süresince internet sitesinin düzgün bir biçimde çalışmasını
          sağlamaktır. Örneğin; birden fazla sayfadan oluşan çevrimiçi
          formlarını doldurabilmeniz bu çerezler sayesinde mümkün olmaktadır.  
          Kalıcı Çerezler: Kalıcı çerezler internet sitesinin işlevselliğini
          artırmak, daha hızlı ve iyi bir hizmet sunmak amacıyla kullanılan
          çerez türleridir. Bu tür çerezler ziyaretçilerin tercihlerini
          hatırlamak için kullanılır ve tarayıcılar vasıtasıyla ziyaretçilerin
          cihazlarında depolanarak belirli bir tarihe veya kullanıcı tarafından
          silinene kadar varlığını sürdürür.  Ayarlarınız ile uyumlu
          kişiselleştirilmiş bir deneyim sunma gibi işlemler için kalıcı
          çerezler kullanılır. Çerez Benzeri Teknolojiler de bu kapsama
          girmektedir. 2.2. Kaynağı bakımından çerezler Birinci taraf çerezleri:
          Doğrudan ziyaret edilen internet sitesi tarafından ziyaretçilerin
          cihazlarına yerleştirilir. Birinci taraf çerezleri gezmekte olduğunuz
          internet sitesi tarafından tanımlanır ve sadece bu site tarafından
          okunabilirler. Üçüncü taraf çerezleri: Üçüncü taraf çerezleri farklı
          hizmetler için kullanılan diğer organizasyonlar tarafından tanımlanır.
          Örneğin, faydalanmakta olduğumuz harici veri analizi hizmetleri ve bu
          hizmeti sunan tedarikçiler tarafından, neyin popüler olup neyin
          olmadığını raporlamak üzere bizim adımıza çerezler tanımlanır.  2.3.
          Kullanım amaçlarına göre çerezler Zorunlu Çerezler: Zorunlu çerezler,
          internet sitemize göz atmanız, internet sitemizin düzgün bir şekilde
          çalışabilmesi, özelliklerinden ve sunulan hizmetlerden
          yararlanabilmeniz için gerekli olan çerezlerdir. Bu çerezler güvenlik
          ve doğrulama gibi amaçlar için kullanılmakta olup, herhangi bir
          pazarlama amacı doğrultusunda kullanılmaz ve kimliğinizi tanımlamaz.
          Aynı oturum içerisinde bir sayfaya geri dönüldüğünde önceki eylemleri
          hatırlama buna örnek verilebilir. Bu çerezlerin devre dışı bırakılması
          hizmet ve özelliklerin tamamının veya bir bölümünün kullanılamaz hale
          gelmesine neden olabilir. İşlevsellik çerezleri: İşlevsellik
          çerezleri, daha kişiselleştirilmiş bir çevrimiçi deneyim için internet
          sitemizde geçmişte yaptığınız seçimlerin (kullanıcı adınız,
          bulunduğunuz bölge veya kullandığınız dil) hatırlamasına olanak tanır.
          Bu çerezlerin topladığı bilgiler, kullanıcı adınız gibi kimliğinizi
          tanımlayabilecek verileri içerebilir. Bu tür çerezlerin kullanımına
          izin verilmemesi size özel bir özelliğin kullanılmamasına neden
          olabilir ve tercihlerinizi hatırlamasını engeller.  Performans
          çerezleri: Bu çerezler, web sitemizi nasıl kullandığınız, hangi
          sayfaları ziyaret ettiğiniz ve hangi bağlantıları tıkladığınız gibi
          bilgileri toplayarak ziyaretçilerin web sitemizle nasıl bir etkileşime
          girdiğini anlamamıza yardımcı olur. Bu çerezler kimliğinizi
          tanımlamaz. Tüm veriler isimsiz bir şekilde alınır ve bir araya
          getirilir. Bu çerezleri kabul ederek internet sitemizi iyileştirmemize
          yardımcı olur, internet sitemizin çalışma şeklini geliştirmemize de
          katkıda bulunursunuz.  Pazarlama çerezleri: Bu çerezler size ve ilgi
          alanlarınıza yakın olan içerikleri ve kampanyaları sunmak amacıyla
          kullanılır. Ayrıca hedefli reklamlar sunmak veya bir reklamın size
          görüntülenme sayısını kısıtlamak amacıyla da kullanılabilir. Bu
          çerezler tarafınıza özel ürün tanıtımı yapmak, kişiselleştirilmiş
          reklamlar göstermek, reklam kampanyalarının etkinliğini ölçmek için
          kullanılabilir.  İşlevsellik, Performans ve Pazarlama Çerezlerinin
          kullanımını tarayıcınızdan her zaman engelleyebilirsiniz. Ancak bunun
          Site kullanımınızı etkileyebileceğini hatırlatmak isteriz. Çerezlerin
          devre dışı bırakılması genellikle web sitesini kullanmaya engel teşkil
          etmez; fakat web sitesinin bazı özelliklerine erişimin kısıtlanmasına
          veya bu özelliklerin kullanılamamasına neden olabilir. Çerezleri
          yönetmenize izin verip vermediğini öğrenmek için tarayıcı ayarlarınıza
          da bakmanızı tavsiye ederiz.
        </div>
      </div>
    </Layout>
  );
}
