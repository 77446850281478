export const faqTr = [
    {
      id: 1,
      title: "Sportimec Nedir?",
      description:
        "Sportimec spor kulüpleri iş birliği ile oyuncuların gerçek hayat  performanslarına endeksli tokenların üretildiği menajerlik oyunu platformunudur. Performans tokenları oyuncu kulübünde oynamaya devam ettiği sürece ikinci el pazarında el değiştirilebilir.",
    },
    {
      id: 2,
      title: "Sporcu Performans Tokenı Nedir?",
      description:
        "Futbol takımının oyuncusunun piyasa değerine eş değer oranda toplam değere sahip olarak çıkartılan ERC20 türü akıllı kontrat türüdür.",
    },
    {
      id: 3,
      title: "Performans tokenı ne işe yarar?",
      description: `
      <li>Fantazi/menajerlik oyunlarında takım kurmak için oyuncuya ait tokenın sahipliği zorunludur.</li>
      <li>Kurduğun takımın başarılarından çeşitli ödül ve promosyonlar kazanabilirsin.</li>
      <li>Oyuncu başka bir kulübe gittiğinde piyasa değerindeki artıştan çeşitli ödül ve promosyonlar kazanırsın.</li>
      <li>Pazar yerinde başka kullanıcılar arasında al/sat yapılabilir.</li>
      `,
    },
    {
      id: 4,
      title: "NFT midir?",
      description:
        "Hayır NFT değildir ERC20 türüdür.",
    },
    {
      id: 5,
      title: "Kulüp FAN tokenu mudur?",
      description:
        "Hayır FAN token değildir. ",
    },
    {
        id: 6,
        title: "Hisse Senedi midir?",
        description:
          "Hayır değildir hiç bir sahiplik hakkı vermez",
      },
      {
        id: 7,
        title: "Oyuncu üzerinde bir mülkiyet hakkı verir mi?",
        description:
          "Hayır hiç bir şekilde üçüncü taraf mülkiyet hakkı vermez ",
      },
      {
        id: 8,
        title: "Her oyuncunun piyasa değeri tokenize edilebilir mi? ",
        description:
          "Evet  tokenize edilebilir.",
      },
      {
        id: 9,
        title: "Oyuncu piyasa değerlemesi nasıl yapılır?",
        description:
          "Oyuncuların performans verileri , istatiksel dataları, karakteristik ve demoğrafik verileri gibi bir çok data  kullanarak AI tabanlı algoritmalar sayesinde objektif bir değerleme yapılır.",
      },
      {
        id: 10,
        title: "Token ne kadar süre ile piyasada işlem görür ?",
        description:
          "Sporcu ilk arzını gerçekleştiren kulübünün oyuncusu olduğu sürece tokenlar el değiştirilebilir saklanabilir.",
      },
      {
        id: 11,
        title: "Token Fiyatı Nasıl Belirlenir?",
        description:
          `<li><b>İlk arz fiyati:</b> Piyasa değerlemesi hesaplanan oyuncunun Pazar verilerine göre toplam token arzı hesaplanır. Piyasa değerinin toplam token arz sayısına bölünmesi sonucu ilk arz birim fiyatı oluşur.</li>
          <li><b>İkinci el al /sat fiyatı: </b> Platformun ikinci el takas piyasasında kullanıcılar arasında gerçekleşen al/sat işlemleri sonucu serbest piyasa koşullarında oluşur.</li>
          `,
      },
      {
        id: 12,
        title: "Oyuncu Başka Kulübe Transfer Olduğunda Token Ne olur?",
        description:
          
        `<li>İlk adım olarak oyuncunun yeni piyasa değeri hesaplanır.</li>  
        <li>Bu yeni piyasa değerine karşılık olarak token kapanış fiyatı hesaplanır.</li>
        <li>Kapanış değeri karşılık dağıtılacak ödül ve promosyonlar token sahiplerine seçenek olarak sunulur.</li>
       <li> Dağıtımı yapılan ödül ve promosyonlar sonrası token yakılarak dolaşımdan kaldırılır.</li>
        `,
      },
      {
        id: 13,
        title: "Ödül ve promosyonlar nelerdir?",
        description:
          "Yeni oyuncu tokenları, kulüp ve oyuncu etkileşimleri, maç biletleri, NFT, Fan Token, crypto coin ve daha bir çok alternatif.",
      },
      {
        id: 14,
        title: "Nasıl üye olunur?",
        description:
          "Andorid ve  IOS  marketlerden uygulamamızı indirip kolayca üye olabilirsiniz. KYC-AML süreçlerini tamamladıktan sonra al/sat işlemleri yapabilirsiniz.",
      },
      {
        id: 15,
        title: "Hesabıma nasıl bakiye yükleyebilirim.?",
        description:
          "Banka hesabınızdan EFT veya havale yapabilir yada crypto cüzdanınızdan desteklenen coinleri sportimec hesabınıza yükleyebilirsiniz.",
      },
      {
        id: 16,
        title: "KYC-AML nedir? ",
        description:
          "Mevzuatlar gereği müşterini tanı ve kara para aklama karşıtı işlemler için gerekli olan kimlik ve adres doğrulama işlemlerinden oluşan süreçtir. ",
      },
      {
        id: 17,
        title: "Yasal mıdır?",
        description:
          "Sportimec platformunda üretilen ürün ve hizmetler yürülükteki kripto varlık regülasyonları ile tamamen uyumludur.",
      },
  ];

  export const faqEN = [
    {
      id: 1,
      title: "What is Sportimec?",
      description:
       `Sportimec, sets up partnerships with clubs and generates and offers performance tokens of players. Tokens are valued by AI-powered algorithms based on players' real-life gaming performance. Also establishes marketplaces for resale of tokens as long as players are in the club.`
    },
    {
        id: 2,
        title: "What is a Player Performance Token?",
        description:
         `It is a type of ERC20 smart contract that is issued with a total value equal to the market value of the player of the football team.`
      },
      {
        id: 3,
        title: "How can use Player Performance Token?",
        description:
         `
         <li>Ownership of the player's token is required to form a team in fantasy/management games.</li>
         <li>You can win various awards and promotions from the achievements of your team.</li>
         <li>When the player moves to another club, you get various rewards and promotions from the increase in market value.</li>
         <li>It is possible to buy/sell between other users in the marketplace.</li>
         `
      },
      {
        id: 4,
        title: "Is that NFT ?",
        description:
         `No, it’s ERC20 smart contract.`
      },
      {
        id: 5,
        title: "Is that club fan token ?",
        description:
         `No it’s not.`
      },
      {
        id: 6,
        title: "Is that security token ?",
        description:
         `No, does not grant proportionate ownership rights.`
      },
      {
        id: 7,
        title: "Do token holders own third-party proprietary rights?",
        description:
         `No, it does not in any way provide third-party ownership rights.`
      },
      {
        id: 8,
        title: "Can every player value be tokenize?",
        description:
         `Yes it can be tokenize.`
      },
      {
        id: 9,
        title: "How to make player market valuation?",
        description:
         `An objective evaluation is made thanks to AI-based algorithms using many data such as performance data, statistical data, characteristic and demographic data of the players.`
      },
      {
        id: 10,
        title: "How To Determine Token Price?",
        description:
         `
            <li><b>Inıtial offerin price:</b> The total token supply is calculated according to the market data of the player whose market valuation is calculated. The initial offering unit price is formed by dividing the market value by the total token offering.</li>
            <li><b>Second hand price:</b> It occurs under free market conditions as a result of buy/sell transactions between users in the marketplace.</li>
         `
      },
      {
        id: 11,
        title: "What happens token when Player transfers to another Sport Club?",
        description:
         `
         <li>As a first step, the new market value of the player is calculated.</li>
         <li>The token closing price is calculated in response to this new market value.</li>
         <li>Prizes and promotions to be distributed in return for closing value are offered to token holders as an option.</li>
         <li>After the distributed awards and promotions, the token is removed from circulation by burning.</li>
         `
      },
      {
        id: 12,
        title: "What kind awards and promitions can be won?",
        description:
         `Fan engagements with clubs and players, new player tokens, crypto coin, NFT, fan token, match tickets and many new alternatives.`
      },
      {
        id: 13,
        title: "How to become a user?",
        description:
         `You can easily become a member by downloading our application from Andorid and IOS markets. After completing the KYC-AML processes, you can make buy/sell transactions.`
      },
      {
        id: 14,
        title: "What is KYC-AML?",
        description:
         `It is the process that consists of identity and address verification processes required for know your customer and anti-money laundering transactions as per the legislation.`
      },
      {
        id: 15,
        title: "How can deposit  to my sportimec account?",
        description:
         `You can make EFT or wire transfer from your bank account or you can transfer supported coins from your crypto wallet to your sportimec account.`
      },

  ];