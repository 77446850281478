import { useEffect } from "react";
import Layout from "../components/composite/Layout";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div
        className="shadow-2xl  mx-[auto] my-[5%] p-6 rounded-md lgx:mx-[5%]"
        style={{
          fontFamily: "Poppins",
          maxWidth: 800,
        }}
      >
        <h1 className=" font-semibold text-[18px] text-[rgba(45,51,57,0.9)] mb-[15px] mt-[2px]">
          DEFİMEC GİZLİLİK POLİTİKASI{" "}
        </h1>
        <div className=" text-[rgba(45,51,57,0.9)] text-[13px]">
          DEFİMEC GİZLİLİK POLİTİKASI Defimec Blokzincir Teknolojileri Yazılım
          ve Sanayi Anonim Şirketi (“biz” veya “Defimec”) olarak 6698 sayılı
          Kişisel Verilerin Korunması Kanunu’nda (“KVKK”) tanımlı veri sorumlusu
          sıfatıyla işlediğimiz kişisel verilerinizin güvenliği ve gizliliğine
          önem veriyoruz. Bu Gizlilik Politikası’nda sizi, kişisel verilerinizin
          toplanması, saklanması, aktarılması da dahil olmak üzere ancak
          bunlarla sınırlı olmaksızın işlenmesi; söz konusu işlemenin amacı,
          yöntemi ve hukuki sebepleri hakkında bilgilendirmek isteriz. İşbu
          Politika’da kullanılan “işlenmiş” ve “işlemek” ifadesi, kişisel
          verilerinizin tamamen veya kısmen otomatik olan ya da herhangi bir
          veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla
          elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
          değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması,
          devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya
          da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen
          her türlü işlemi ifade etmektedir. Defimec Gizlilik Politikası,
          üzerlerinde herhangi bir kontrolümüz olmaması sebebiyle, Defimec
          platformları’nda bulunan web bağlantıları aracılığıyla eriştiğiniz
          diğer websitelerinde, uygulamalarda, diğer mecralarda ve programlarda
          geçerli değildir. Ayrıca, işbu Gizlilik Politikası zaman zaman
          platformlar aracılığıyla değiştirilebilir. Değişikliklerden haberdar
          olmak adına bu belgeye periyodik olarak bakmanızı tavsiye ederiz.
          Platformlarda her zaman işbu Gizlilik Politikası’nın en son sürümü
          yayınlanacaktır. Verilerin Toplanması Defimec tarafından sahip olunan
          veya işletilen mobil uygulamalar, http://defimec.com , ve diğer web
          adreslerimiz, diğer platformlar (birlikte “platform(lar)”)
          aracılığıyla sizlere sunulan herhangi bir ürün veya hizmeti
          (“hizmetler”) ya da platformlar üzerinden sağlanan diğer içerikleri
          kullanarak, Defimec ile paylaştığınız kişisel verilerinizin
          işlenmesine, Defimec Gizlilik Politikası (“Politika”), KVKK, 6502
          sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler
          Yönetmeliği’nden doğan yükümlülüklerin ifası ve ilgili mevzuat
          uyarınca izin vermektesiniz. Defimec (i) platformlar’ı kullanırken
          gönüllü olarak paylaştığınız bilgileri ve (ii) platformlar’da yer alan
          hizmetleri kullanırken, Politika’da belirtilen amaçlara uygun şekilde
          otomatik olarak elde edilen bilgileri sadece yasaların izin verdiği
          ölçüde toplar. Verilerin Toplanma Amacı, Yöntemi ve Hukuki Sebepleri
          Şirketimiz tarafından Defimec platformlarını ziyaret etmenizle
          birlikte veya işbirliği içerisinde olduğumuz iş ortakları aracılığıyla
          başta trafik verileriniz olmak üzere aşağıda belirtilen kişisel
          verileriniz beraberinde açıklanan amaçlarla bağlantılı, sınırlı ve
          ölçülü bir şekilde elektronik olarak, otomatik ve otomatik olmayan
          yöntemlerle KVK mevzuatına uygun olarak Şirketimiz tarafından
          işlenmektedir. Aşağıdaki tabloda sıralanan verileriniz KVKK madde
          5/2-a kanunlarda açıkça öngörülmesi (Türk Ticaret Kanunu, Sigortacılık
          Kanunu vs.); madde 5/2-c hizmetlerimizden faydalanabilmeniz için
          gerekli sözleşmelerin kurulması ve ifasıyla doğrudan doğruya ilgili
          olması; madde 5/2-f şirketimizin meşru menfaatleri ve madde 5/2-ç
          hukuki yükümlülüklerin yerine getirilmesi için zorunlu olması hukuki
          sebeplerine dayalı olarak açık rızanız aranmaksızın işlenebilir.
          Kişisel verilerinizi, ürünlerimizi ve hizmetlerimizi
          gereksinimlerinizi karşılayacak şekilde uyarlamak amacıyla, pazarlama,
          tüketici profilleri veya demografik araştırmalar için dâhili
          istatistikler oluşturmak amacıyla kullanabiliriz. Amacımız
          kullanıcılarımızı daha iyi anlamak ve kullanıcılarımıza daha iyi
          hizmet vermektir. Yukarıda belirtilen kişisel bilgilerinizi, yurt içi
          veya yurt dışında soruşturmalar, kovuşturmalar veya soruşturmalarla
          bağlantılı olarak mahkeme, adli veya idari düzenleyici otoriteler gibi
          üçüncü kişiler ya da hukuki veya düzenleyici otoritelerce getirilen
          yükümlülüklere uymak için işleyebilir ve paylaşabiliriz. Söz konusu
          otoritelerce izin verildiği ölçüde ve bir suçun önlenmesi veya tespit
          edilmesine halel getirmedikçe, bu tür talepleri size yönlendirmek veya
          yanıt vermeden önce sizi bilgilendirmek için elimizden gelen gayreti
          göstereceğiz. Verilerinizin Depolanması ve Doğruluğu Defimec, sizin
          tarafınızdan sağlanan verileri güvenli bir şekilde depolar ve
          yürürlükteki mevzuatın gerektirdiği şekilde, paylaştığınız bilgilerin
          yetkisiz erişimini veya toplanmasını önlemek için gerekli tüm
          önlemleri alır. Hizmetlerimizden faydalanmak için ilgili üçüncü bir
          hizmet sağlayıcıya kişisel veya başka bir veri sağlamanız halinde, bu
          verinin sağlanması ya da işlemin gerçekleştirilmesi sonucu olarak
          ortaya çıkan bilgilerin doğruluğu, eksiksizliği ve diğer maddi veya
          manevi olmayan herhangi bir konu, kayıp, zarardan münhasıran sorumlu
          olduğunuzu kabul edersiniz. Güvenlik Düzeyi Defimec, kişisel
          bilgilere, bunların bütünlüğüne ve güvenliğinin korunmasına önem
          vermektedir. Bu nedenle, tarafınızca sağlanan verilerin
          değiştirilmemesini ve herhangi bir bilgiye yetkisiz kişilerce
          erişilmemesi için kişisel bilgilerinizin sıkı bir şekilde izlendiği
          yüksek güvenlikli veri tabanlarımızda güvenle saklanmasını sağlıyoruz.
          Bilgilerinizin Kiminle Paylaşıldığı Defimec olarak, hizmetlerimizi
          yerine getirmek için gerektirdiği ölçüde kişisel bilgilerinizi,
          tedarikçilerimiz, çözüm ortaklarımız ve yetkili kamu kurum ve
          kuruluşlarıyla paylaşmamız gerekmektedir. Kişisel verilerinizi, hukuki
          ve düzenleyici otoritelerce getirilen yükümlülükler kapsamında yetkili
          kamu kurumları ile; güvenlik, risk yönetimimizi geliştirmek ve
          kullanıcı deneyimini en üst düzeyde tutmak üzere kullanıcı istatistik
          raporları oluşturmak için istatistik hizmeti veren kuruluşlarla
          paylaşmaktayız. Defimec, hizmet kapsamı gerektirdiği ölçüde kişisel
          bilgilerinizi, Türkiye’de veya yurtdışında bulunan, ödeme hizmet
          sağlayıcısı veya diğer hizmet sağlayıcıları gibi, yüklenicilerine,
          acentelerine, danışmanlarına (hukuki, finansal, vergisel veya diğer
          danışmanlar gibi) veya Defimec’in ana hissedarı ve ana hissedarının
          ortak olduğu diğer şirketlere ve Defimec adına faaliyette bulunan
          diğer üyeler ile paylaşabilir. Defimec bu verileri bir ürün siparişine
          yanıt olarak üçüncü taraf satıcılara yönlendirebilir veya sizden
          Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik
          kapsamında ayrıca izin almak kaydıyla sizi ticari bülten dolaşım
          listesine ekleyebilir. Hizmet sağlayıcımız olan harici web siteleri ve
          şirketler, kişisel olarak tanımlanabilir bilgilerinize
          platformlarımızdaki bağlantılar aracılığıyla erişebilirler. Bu gibi
          hallerde üçüncü taraflarca veri toplanması, işlenmesi, paylaşılması
          veya aktarımı sorumluluğumuzun dışındadır. Lütfen ilgili web siteleri
          ve şirketlerin gizlilik politikasına doğrudan başvurunuz. Çerezler
          Defimec Çerez Politikası’nda detaylı olarak belirttiğimiz şekilde,
          diğer birçok internet sitesi gibi biz de “çerezlerden”
          faydalanmaktayız. Çerezler bir internet sayfası sunucusu tarafından
          sabit sürücünüze iletilen küçük metin dosyalarıdır. Bu yolla, IP
          adresi, kullanılan tarayıcı, bilgisayarınızdaki işletim sistemi ve
          internet bağlantınız gibi belirli verileri otomatik olarak elde
          ederiz. Çerezler programları çalıştırmada veya bilgisayarınıza
          virüsler göndermede kullanılamaz. Çerezlerin içerdiği bilgilerle biz
          sadece navigasyonu kolaylaştırabilir ve internet sitelerimizin doğru
          görüntülenmesini sağlayabiliriz. Sizi farklı hizmetlerde tanımak,
          hizmetlerimiz üzerinde ve dışında ilgi alanlarınız hakkında bilgi
          sahibi olmak, deneyiminizi geliştirmek, güvenliği arttırmak,
          hizmetlerimizin kullanımını ve etkinliğini ölçmek amacıyla mobil
          uygulama tanımlayıcıları gibi çerez ve benzeri teknolojiler
          kullanırız. Tarayıcı ayarları ve diğer araçlar aracılığıyla çerezleri
          kontrol edebilirsiniz. Her durumda, topladığımız veriler sizin
          onayınız olmadan üçüncü şahıslarla paylaşılamaz. Elbette ki zorunlu
          çerezler hariç olmak üzere çerezler olmadan internet sitemizi her
          zaman görüntüleyebilirsiniz. İnternet tarayıcılarının varsayılan
          ayarları, çerezleri kabul edecek şekilde kuruludur. Tarayıcı
          ayarlarınız vasıtasıyla çerezlerin kullanımını her zaman
          durdurabilirsiniz. Bu ayarların nasıl değiştirileceğini öğrenmek için
          lütfen tarayıcınızın yardım fonksiyonunu kullanınız. Çerezlerin
          kullanımını durdurduğunuzda platformlarımızın belirli özelliklerinin
          çalışmayabileceğini lütfen unutmayınız. Kişisel Verilerinizin
          Korunması İçin Alınan Tedbirler Kişisel verileriniz tarafımızca
          işlenirken karşılaşacağınız ana riskler, verilerinizin kaybolması,
          çalınması veya kötüye kullanılmasıdır. Bu haller kişisel ve özel
          bilgilerinizin kamuya açık hale gelmesi veya sahtekarlık ve
          dolandırıcılıkta kullanılabilmesine yol açabilir. Defimec olarak,
          kişisel verilerinizin kötüye kullanılması veya değiştirilmesini
          önlemek adına tüm teknik ve organizasyonel önlemleri almaktayız.
          Sizden toplayabileceğimiz herhangi bir kişisel verinin gizliliğini
          korumak adına şifreleme, güvenlik duvarı, parola kurtarma ve koruma
          sistemleri gibi endüstri standardı uygulamaları kullanmaktayız.
          Kişisel verilerinizi en iyi şekilde korumaya devam etmek adına yeni
          teknolojileri ve güncel yöntemleri güvenlik sistemlerimize uyarlıyor
          ve yeniliyoruz. Ayrıntılı bilgi için lütfen Veri Saklama ve İmha
          Politikası’nı inceleyiniz. Her ne kadar, işlediğimiz ve sakladığımız
          kişisel verilerinizi korumak adına gerekli tedbirleri alsak da hiçbir
          güvenlik sisteminin tüm olası güvenlik ihlallerini engelleme imkânı
          yoktur. Bu sebeple toplanan, saklanan veya tarafımızca kullanılan
          hiçbir kişisel verinin tam güvenliğini garanti edemiyoruz. Hesabınızın
          ve kişisel verilerinizin asıl kontrolü ve güvenliğinin siz
          kullanıcılar elinde olduğunu belirtmek isteriz. Kişisel verileriniz
          tarafımıza ulaştığında, yetkisiz erişimi önlemek adına sıkı güvenlik
          önlemleri ve tedbirleri kullanmaktayız. Şirketimiz, gerekli gördüğü
          durumlarda farklı kuruluşlardan destek hizmeti almakta ve ilgili
          kuruluşların Şirketin gizlilik standartlarına ve şartlarına uygun
          hareket etmesini sağlamaktadır. Sözleşme düzenlediği veri işleyenlerin
          bilgi güvenliğine en az kendisi kadar önem verdiklerinden ve müşterek
          sorumluluğun bilinciyle hareket ettiklerinden emin olmakta ve bunu
          sözleşmesel olarak da güvenceye almaktadır. Veri işleyenler,
          mevzuattaki tanım ile paralel olarak yalnız Defimec’in talimatları
          doğrultusunda, Defimec ile akdedilmiş sözleşme çevçevesinde kalmak
          suretiyle ve mevzuata uygun olarak kişisel verilerinizi işleyebilir.
          Kişisel Verilerinizin Muhafaza Edilme Süreleri Kişisel verilerinizi,
          işleme amacımız adına gereken sürelerden daha uzun bir süre
          saklamamaktayız. Kişisel verilerinizi muhafaza ettiğimiz süre,
          kullanılan ve toplanılan bilgilerin tarafımızca hangi amaçla
          kullanıldığına bağlı olmakla beraber kanuni olarak yükümlülüklerimizi
          yerine getirmek, ilgili mevzuatlara uymak ve yasal haklarımızı korumak
          adına belirlenmektedir. Kişisel verilerinizin işlenmesine ilişkin
          olarak itiraz hakkınızı kullandığınız takdirde, itirazda bulunduğunuz
          kişisel verileriniz tarafımızca değerlendirilecek ve kişisel
          verileriniz hukuki ve idari otoriteler yasal olarak saklama
          yükümlülüğümüz bulunmadığı takdirde derhal silinecektir. Kanun ve
          ilgili diğer mevzuatlar uyarınca, kişisel verilerinizin tarafımızca en
          fazla on (10) yıla kadar saklanılması beklenmektedir. Ayrıntılı bilgi
          için lütfen Veri Saklama ve İmha Politikamızı inceleyiniz. Uygulanacak
          Hukuk ve Yargı Yeri İşbu Politika Türk hukukuna tabi olup işbu
          Politika’nın yorumlanmasından, uygulanmasından doğacak her türlü
          ihtilafın çözümünde İstanbul Anadolu Mahkemeleri yetkili olacaktır.
          Haklarınız Defimec sizlere, kendi kişisel bilgilerinizin kullanım
          şeklini gözlemleme hakkı verir. Bilgilerinizi paylaşılmasına izin
          verdiğiniz ölçüde, üçüncü kişilerle paylaşabilir ve bu yetkiyi
          sonlandırma hakkınız baki olmak üzere tanıtım amaçlı e-postalar,
          bültenler, bilgilendirmeler gönderebilir. Kişisel verilerinizle ilgili
          olarak, KVKK madde 11 uyarınca sahip olduğunuz haklar aşağıda
          sayılmıştır: a) Kişisel verilerinizin işlenip işlenmediğini öğrenme,
          b) Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme, c)
          Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
          kullanılmadığını öğrenme, d) Yurt içinde veya yurt dışında kişisel
          verilerin aktarıldığı üçüncü kişileri bilme, e) Kişisel verilerin
          eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
          isteme, f) 6698 Sayılı Kanun 7 maddede öngörülen şartlar çerçevesinde
          kişisel verilerin silinmesini veya yok edilmesini isteme, g) İşbu
          maddenin (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel
          verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, h)
          İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
          edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
          çıkmasına itiraz etme, i) Kişisel verilerin kanuna aykırı olarak
          işlenmesi sebebiyle zarara uğranması hâlinde zararın giderilmesini
          talep etme. Bu çerçevede, söz konusu haklarınızı kullanmak için,
          kişisel kimlik bilgilerinizi ve yukarıda belirtilen haklardan
          hangisini kullanmak istediğinizi belirten talep mektubunuzu aşağıdaki
          adrese veya e-posta adresine gönderin: Adres: Barış Mah. Koşu Yolu
          Cad. Kuluçka Merkezi Blok No: 30 Gebze / Kocaeli E-posta:
          bilal.tekin@sportimec.com
        </div>
      </div>
    </Layout>
  );
}
