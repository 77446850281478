import { useEffect } from "react";
import Layout from "../components/composite/Layout";

export default function PersonalDataRetentionandDisposalPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div
        className="shadow-2xl  mx-[auto] my-[5%] p-6 rounded-md lgx:mx-[5%]"
        style={{
          fontFamily: "Poppins",
          maxWidth: 800,
        }}
      >
        <h1 className=" font-semibold text-[18px] text-[rgba(45,51,57,0.9)] mb-[15px] mt-[2px]">
          DEFİMEC KİŞİSEL VERİ SAKLAMA VE İMHA POLİTİKASI{" "}
        </h1>
        <div className=" text-[rgba(45,51,57,0.9)] text-[13px]">
          Giriş İşbu Kişisel Veri Saklama ve İmha Politikası (“Politika”), 6698
          sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) ile kişisel
          verilerin korunmasına yönelik diğer yürürlükteki mevzuatlara (“KVKK
          Düzenlemeleri”) uygun olarak hazırlanmıştır. İşbu Metin, Defimec
          Gizlilik Politikası’nın ayrılmaz bir parçasıdır ve aksi işbu
          Politika’da tanımlanmadıkça, Gizlilk Politikası’ndaki tanımlar işbu
          Politika için de geçerli olacaktır. Politikanın Amacı ve Kapsamı İşbu
          Politika; kişisel verilerin korunması hakkındaki düzenlemeleri yerine
          getirmek ve kişisel verilerin işlendikleri amaç için gerekli olan
          azami saklama süresini belirleme esasları ile silme, yok etme ve
          anonim hale getirme süreçleri hakkında kişisel veri sahiplerini
          bilgilendirmek amacıyla veri sorumlusu sıfatıyla Defimec Blokzincir
          Teknolojileri Yazılım ve Sanayi Ticaret Anonim Şirketi tarafından
          hazırlanmıştır. İşbu Politika; Kullanıcılarımızın, çalışanlarımızın,
          önceki çalışanlarımızın, çalışan adaylarımızın, şirket
          ortaklarının/yetkililerinin, ziyaretçilerimizin ve üçüncü kişilerin
          otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak
          kaydıyla otomatik olmayan yollarla işlenen tüm kişisel verilerine
          ilişkindir. İşbu Politika, Şirket’in kişisel veriler üzerinde
          uygulayacağı tüm imha faaliyetlerini kapsayacak olup, her türlü imha
          gereksinimi sonucunda uygulanacaktır. Tanımlar Kişisel Veri : Kimliği
          belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.
          Kişisel Veri Sahibi : Kişisel verisi işlenen gerçek kişi. Veri
          Sorumlusu : Kişisel verilerin işlenme amaçlarını ve vasıtalarını
          belirleyen, verilerin sistematik bir şekilde tutulduğu yeri (veri
          kayıt sistemi) yöneten kişi. Açık Rıza : Belirli bir konuya ilişkin,
          bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza. İlgili
          Kullanıcı : Verilerin teknik olarak depolanması, korunması ve
          yedeklenmesinden sorumlu olan kişi ya da birim hariç olmak üzere veri
          sorumlusu organizasyonu içerisinde veya veri sorumlusundan aldığı
          yetki ve talimat doğrultusunda kişisel verileri işleyen kişilerdir.
          İmha : Kişisel verilerin silinmesi, yok edilmesi veya anonim hale
          getirilmesi. Kayıt Ortamı : Tamamen veya kısmen otomatik olan ya da
          herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
          olmayan yollarla işlenen kişisel verilerin bulunduğu her türlü ortam.
          Kişisel Verilerin Anonim Hale Getirilmesi: Kişisel verilerin, başka
          verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya
          belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle
          getirilmesi. Kişisel Verilerin Silinmesi: Kişisel verilerin ilgili
          kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz
          hale getirilmesi. Kişisel Verilerin Yok Edilmesi: Kişisel verilerin
          hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve
          tekrar kullanılamaz hale getirilmesi işlemi. Periyodik İmha: KVK
          Düzenlemeleri’nde yer alan kişisel verilerin işlenme şartlarının
          tamamının ortadan kalkması durumunda kişisel verileri saklama ve imha
          politikasında belirtilen ve tekrar eden aralıklarla resen
          gerçekleştirilecek silme, yok etme veya anonim hale getirme işlemi.
          Kurul: Kişisel Verileri Koruma Kurulu. KVKK: 6698 sayılı Kişisel
          Verilerin Korunması Kanunu. Yönetmelik: 28 Ekim 2017 tarihli ve 30224
          sayılı Resmî Gazete’de yayımlanan Kişisel Verilerin Silinmesi, Yok
          Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik. Kişisel
          Verilerin Saklanması ve İmhasında Uygulanacak İlkeler Şirket, kişisel
          verilerin saklanması ve imhasında aşağıda yer alan ilkeler
          çerçevesinde hareket etmektedir: Kişisel verilerin silinmesi, yok
          edilmesi, anonim hale getirilmesiyle ilgili yapılan bütün işlemler
          Defimec tarafından kayıt altına alınmakta ve söz konusu kayıtlar,
          diğer hukuki yükümlülükler hariç olmak üzere en az 3 (üç) yıl süreyle
          saklanmaktadır. KVKK’nın 5. ve 6. maddelerinde yer alan kişisel
          verilerin işlenme şartlarının tamamının ortadan kalkması halinde,
          kişisel veriler Şirket tarafından re’sen veya kişisel veri sahibinin
          talebi üzerine silinmekte, yok edilmekte veya anonim hale
          getirilmektedir. Bu hususta kişisel veri sahibi KVKK’nın 11. ve 13.
          maddesine istinaden kendisine ait kişisel verilerin silinmesini veya
          yok edilmesini talep edebilir. KVKK madde 11/e maddesi uyarınca, veri
          sahibinin Şirket’e başvurarak KVKK 7. maddede öngörülen şartlar
          çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme
          hakkı bulunmaktadır. KVKK madde 13’e göre; Kişisel verileri işleme
          şartlarının tamamı ortadan kalkmışsa, veri sorumlusu talebe konu
          kişisel verileri siler, yok eder veya anonim hale getirir. Veri
          sorumlusu, kişisel veri sahibinin talebini en geç 30 gün içinde
          sonuçlandırır ve kişisel veri sahibine bilgi verir. Kişisel verileri
          işleme şartlarının tamamı ortadan kalkmış ve talebe konu olan kişisel
          veriler üçüncü kişilere aktarılmışsa veri sorumlusu bu durumu üçüncü
          kişiye bildirir, üçüncü kişi nezdinde Yönetmelik kapsamında gerekli
          işlemlerin yapılmasını temin eder. Kişisel verileri işleme şartlarının
          tamamı ortadan kalkmamışsa, bu talep veri sorumlusunca KVKK’nın 13.
          maddesinin üçüncü fıkrası uyarınca gerekçesi açıklanarak
          reddedilebilir ve ret cevabı kişisel veri sahibine en geç 30 gün
          içinde yazılı olarak ya da elektronik ortamda bildirilir. Şirket,
          Kurul tarafından aksine bir karar alınmadıkça, kişisel verileri resen
          silme, yok etme veya anonim hale getirme yöntemlerinden uygun olanı
          seçmektedir. Ancak, kişisel veri sahibinin talebi olması halinde uygun
          yöntem gerekçesi açıklanarak seçilecektir. Kayıt Ortamları Şirket
          tarafından edinilmiş ve tamamen veya kısmen otomatik olan ya da
          herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
          olmayan yollarla işlenen kişisel verilerin bulunduğu her türlü ortam
          kayıt ortamı olarak kabul edilmektedir. Şirket, işbu Politika ile
          kişisel veri içeren ve aşağıda sayılmış olan ortamlar ve bunlara ek
          olarak ortaya çıkabilecek diğer ortamlardaki kişisel verileri
          Politika’nın kapsamına dâhil edecktir. [Şirket tarafından tutulan
          kayıt ortamları maddeler halinde girilmelidir] [Elektronik ortamlar
          maddeler halinde girilmelidir] [Fiziksel ortamlar maddeler halinde
          girilmelidir] Saklama ve İmhayı Gerektiren Sebepler Şirketimiz ile
          paylaştığınız kişisel veriler, sizlere sunduğumuz Ürün ve İçerikler’e
          ilişkin hizmetlerimizi, hukukun ve teknolojinin gereklerine uygun
          şekilde sunmak ve geliştirmek; bu kapsamda işlem sahibinin bilgilerini
          tespit için kimlik, adres ve diğer gerekli bilgileri kaydetmek; ilgili
          mevzuat uyarınca zorunlu olduğu veya faaliyetlerimiz uyarınca gerekli
          olduğu kapsamda, ödeme sistemleri, elektronik sözleşme veya kağıt
          ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek;
          mevzuat gereği ve diğer otoritelerce öngörülen bilgi saklama,
          raporlama, bilgilendirme yükümlülüklerine uymak ve kamu güvenliğine
          ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep halinde ve mevzuat
          gereği savcılıklara, mahkemelere ve ilgili kamu görevlilerine bilgi
          verebilmek ve işbu Politika’da belirtildiği kapsamda üçüncü kişilerle
          paylaşmak için KVKK ve ilgili ikincil düzenlemelere uygun olarak
          işlenmektedir. Şirketimiz, ayrıca kişisel bilgilerinizi sadece KVKK ve
          yürürlükteki ilgili mevzuata uygun olarak işler. Şirket, Ürün ve
          hizmet kalitesini yükseltmek, Kullanıcı’ların satın aldığı veya
          kullandığı Ürün’leri ve İçerik’leri teslim etmek ve geliştirmek ve
          müşteri memnuniyetini en üst düzeye çıkarmak için kişisel
          bilgilerinizi şirket içerisinde kullanır. Şirket, kişisel
          verilerinizi, Ürünler’imizi ve İçerik’lerimizi, gereksinimlerinizi
          karşılayacak şekilde uyarlamak için pazarlama, tüketici profilleri
          veya demografik araştırmalar için dâhili istatistikler oluşturmak
          amacıyla kullanabilir. Amacımız müşterilerimizi daha iyi anlamak ve
          daha iyi hizmet vermektir. Yönetmelik uyarınca: İşlenmesine esas
          teşkil eden ilgili mevzuat hükümlerinin değiştirilmesi veya ilgası,
          İşlenmesini veya saklanmasını gerektiren amacın ortadan kalkması,
          Kişisel verileri işlemenin sadece açık rıza şartına istinaden
          gerçekleştiği hallerde, ilgili kişinin açık rızasını geri alması,
          KVKK’nın 11. maddesi gereği ilgili kişinin hakları çerçevesinde
          kişisel verilerinin silinmesi ve yok edilmesine ilişkin yaptığı
          başvurunun Şirket tarafından kabul edilmesi, Şirket’in, ilgili kişi
          tarafından kişisel verilerinin silinmesi, yok edilmesi veya anonim
          hale getirilmesi talebi ile kendisine yapılan başvuruyu reddetmesi,
          verdiği cevabı yetersiz bulması veya KVKK’da öngörülen süre içinde
          cevap vermemesi hallerinde; Kurula şikâyette bulunması ve bu talebin
          Kurul tarafından uygun bulunması, Kişisel verilerin saklanmasını
          gerektiren azami sürenin geçmiş olması ve kişisel verileri daha uzun
          süre saklamayı haklı kılacak herhangi bir şartın mevcut olmaması,
          durumlarında, Şirket tarafından ilgili kişinin talebi üzerine silinir,
          yok edilir ya da re’sen silinir, yok edilir veya anonim hale
          getirilir. Kişisel Verilerin Güvenli Bir Şekilde Saklanması ile Hukuka
          Aykırı Olarak İşlenmesi ve Erişilmesinin Önlenmesi ve Hukuka Uygun
          Olarak İmha Edilmesi İçin Alınan Tedbirler Kişisel verilerin güvenli
          bir şekilde saklanması, hukuka aykırı olarak işlenmesi ve
          erişilmesinin önlenmesi ile kişisel verilerin hukuka uygun olarak imha
          edilmesi için KVKK’nın 12. maddesi ve 6/4 maddesi gereği; özel
          nitelikli kişisel veriler için Kurul tarafından belirlenerek ilan
          edilen yeterli önlemler çerçevesinde Şirket tarafından teknik ve idari
          tedbirler alınır. Şirket tarafından, işlediği kişisel verilerle ilgili
          olarak anılan teknik tedbirler aşağıdaki gibidir: [Şirket nezdinde
          alınan teknik tedbirler girilmelidir] Yetki Matrisi Yetki Kontrol
          Erişim Logları Kullanıcı Hesap Yönetimi Ağ Güvenliği Uygulama
          Güvenliği Şifreleme Sızma Testi Saldırı Tespit ve Önleme Sistemleri
          Log Kayıtları Veri Maskeleme Veri Kaybı Önleme Yazılımları Yedekleme
          Güvenlik Duvarları Güncel Anti-Virüs Sistemleri Silme, Yok Etme veya
          Anonim Hale Getirme Anahtar Yönetimi [Şirket nezdinde alınan idari
          tedbirler girilmelidir] Kişisel Veri İşleme Envanteri Hazırlanması
          Kurumsal Politikalar (Erişim, Bilgi Güvenliği, Kullanım, Saklama ve
          İmha vb.) Sözleşmeler (Veri Sorumlusu - Veri Sorumlusu, Veri Sorumlusu
          - Veri İşleyen Arasında ) Gizlilik Taahhütnameleri Kurum İçi Periyodik
          ve/veya Rastgele Denetimler Risk Analizleri İş Sözleşmesi, Disiplin
          Yönetmeliği (Kanuna Uygun Hükümler İlave Edilmesi) Kurumsal İletişim
          (Kriz Yönetimi, Kurul ve İlgili Kişiyi Bilgilendirme Süreçleri, İtibar
          Yönetimi vb.) Eğitim ve Farkındalık Faaliyetleri (Bilgi Güvenliği ve
          Kanun) İmha Yöntemleri Kişisel Verilerin Korunması Hakkında
          Yönetmelik’te öngörülen süre ya da işlendikleri amaç için gerekli olan
          saklama süresinin sonunda kişisel veriler, Şirket tarafından re’sen
          veya ilgili kişinin başvurusu üzerine yine ilgili mevzuat hükümlerine
          uygun olarak aşağıda belirtilen tekniklerle imha edilir. Şirket
          tarafından en çok kullanılan silme, yok etme ve anonim hale getirme
          yöntemleri aşağıda sıralanmaktadır: Kişisel Verileri Silme Yöntemleri:
          [Şirket tarafından kullanılması planlanan silme yöntemleri
          girilmelidir] Kişisel Verileri Yok Etme Yöntemleri: [Şirket tarafından
          kullanılması planlanan yok etme yöntemleri girilmelidir] Kişisel
          Verileri Anonim Hale Getirme Yöntemleri: [Şirket tarafından
          kullanılması planlanan anonim hale getirme yöntemleri girilmelidir]
          Kişisel Verileri Saklama ve İmha Süreleri Şirket, KVK
          Düzenlemeleri’nde öngörülmesi durumunda kişisel verileri bu
          mevzuatlarda belirtilen süre boyunca saklamaktadır. Kişisel verilerin
          ne kadar süre boyunca saklanması gerektiğine ilişkin mevzuatta bir
          süre düzenlenmemişse, kişisel veriler Şirket’in o veriyi işlerken
          yürütülen faaliyet ile bağlı olarak Şirket uygulamaları ve ticari
          yaşamının teamülleri uyarınca işlenmesini gerektiren süre kadar
          işlenmekte daha sonra silinmekte, yok edilmekte veya anonim hale
          getirilmektedir. Kişisel verilerin işlenme amacı sona ermiş, ilgili
          KVK Düzenlemeleri ve Şirket’in belirlediği saklama sürelerinin de
          sonuna gelinmişse, kişisel veriler yalnızca olası hukuki
          uyuşmazlıklarda delil teşkil etmesi veya kişisel veriye bağlı ilgili
          hakkın ileri sürülebilmesi veya savunmanın tesis edilmesi amacıyla
          saklanabilmektedir. Kişisel verilerin silinmesi, yok edilmesi ve
          anonim hale getirilmesiyle ilgili yapılan bütün işlemler kayıt altına
          alınır ve söz konusu kayıtlar, diğer hukuki yükümlülükler hariç olmak
          üzere en az 3 (üç) yıl süreyle saklanır. Periyodik İmha Süreleri
          Yönetmelik’in 11. maddesi gereğince Şirket, periyodik imha süresini 6
          ay olarak belirlemiştir. Buna göre, Şirket’te her yıl Haziran ve
          Aralık aylarında periyodik imha işlemi gerçekleştirilir. Kişisel Veri
          İşleme Envanteri Kişisel Veri İşleme Envanteri, Yönetmelik’in 4.
          maddesinde tanımlanmış olup Şirket Kişisel Veri İşleme Envanteri’nde;
          kişisel veri işleme amaçları ve hukuki sebebi, veri kategorisi,
          aktarılan alıcı grubu ve veri konusu kişi grubuyla ilişkilendirerek
          oluşturdukları, kişisel verilerin işlendikleri amaçlar için gerekli
          olan azami muhafaza edilme süresi, yabancı ülkelere aktarımı öngörülen
          kişisel veriler ve veri güvenliğine ilişkin alınan tedbirler yer
          almaktadır. Şirket’imize başvurarak, Kişisel Veri İşleme Envanteri
          hakkında detaylı bilgiye ulaşabilirsiniz. Yürürlük Şirket tarafından
          hazırlanan işbu Politika [tarih girilmelidir] tarihinde yürürlüğe
          girmiştir. İşbu Politika KVK Düzenlemelerinin gerektirmesi halinde
          yahut kişisel verilerin silinmesi, yok edilmesi veya anonim hale
          getirilmesi yöntemlerinde gerçekleşecek değişiklikler çerçevesinde
          Şirket’in gerekli gördüğü hallerde, zaman zaman değiştirilebilir. İşbu
          Politika’da yapılan değişiklikler aşağıdaki tabloda yer almaktadır.
        </div>
      </div>
    </Layout>
  );
}
