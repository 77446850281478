import { useTranslation } from "react-i18next";

export default function Banner() {
  const { t } = useTranslation();

  return (
    <div className="container" id="banner">
      <div className="flex gap-[25px] items-center lgx:flex-col-reverse">
        <div className="lgx:text-center">
          <div className="mb-[25px] flex flex-col lgx:mb-[15px] lgx:items-center">
            <h1 className="text-[40px] tracking-[-0.5px] leading-[54px] text-[#2E3A4B] lgx:text-[33px] lgx:leading-[40px]">
              {t("Buy_sell_or_swap")}
            </h1>
            <h1 className="text-[40px] tracking-[-0.5px] leading-[54px] text-[#0089EA] lgx:text-[33px] lgx:leading-[40px]">
              {t("real_players_performance_tokens")}
            </h1>
            <h1 className="text-[40px] tracking-[-0.5px] leading-[54px] text-[#2E3A4B] lgx:text-[33px] lgx:leading-[40px]">
              {t("and_build_your_team!")}
            </h1>
          </div>
          <p className="text-[16px] leading-[24px] font-light text-[#6D7C89] mb-[15px] lgx:text-[14px] lgx:leading-[21px]">
            {t("Sportimec_isan_internal_product_of_Defimec")}
          </p>
          <div className="flex gap-[9px] items-center lgx:justify-center">
            <img
              src="./images/GooglePlay.png"
              alt="header image"
              className="w-[157px] h-[45px]"
            />
            <img
              src="./images/AppStore.png"
              alt="header image"
              className="w-[157px] h-[45px]"
            />
          </div>
        </div>
        <img
          src="./images/header-image.png"
          alt="header image"
          className="w-[600px] h-[600px] lgx:w-full lgx:h-[383px] lgx:object-contain"
        />
      </div>
    </div>
  );
}
