import { Button, LinearProgress } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

const rows = [
  {
    player: { name: "Alec Harrison", photo: "./images/profilePhoto.png" },
    club: "Galactica F.C.",
    tokenName: "SPM-ALECHARRISON",
    soldTotalSupply: "7,198,255",
    value: 39,
    remaining: new Date(2023, 1),
    price: "$1.25",
  },
  {
    player: { name: "Theo Feron", photo: "./images/profilePhoto2.png" },
    club: "Frozen Stompers",
    tokenName: "SPM-THEOFERON",
    soldTotalSupply: "7,198,255",
    value: 90,
    remaining: new Date(2023, 1),
    price: "$2.00",
  },
  {
    player: { name: "Rowan Reed", photo: "./images/profilePhoto3.png" },
    club: "Excellent Generals",
    tokenName: "SPM-ROWANREED",
    soldTotalSupply: "7,198,255",
    value: 67,
    remaining: new Date(2023, 1),
    price: "$1.90",
  },
  {
    player: { name: "Fred Fox", photo: "./images/profilePhoto4.png" },
    club: "Strong Titans",
    tokenName: "SPM-FREDFOX",
    soldTotalSupply: "7,198,255",
    value: 30,
    remaining: new Date(2023, 1),
    price: "$1.60",
  },
  {
    player: { name: "Paul Cole", photo: "./images/profilePhoto5.png" },
    club: "Striped Devils",
    tokenName: "SPM-PAULCOLE",
    soldTotalSupply: "7,198,255",
    value: 72,
    remaining: new Date(2023, 1),
    price: "$0.85",
  },
];

const rows2 = [
  {
    player: { name: "Alec Harrison", photo: "./images/profilePhoto.png" },
    club: "Galactica F.C.",
    tokenName: "SPM-ALECHARRISON",
    change: "+6.33",
    volume: "$5,757,926",
    price: "$1.25",
    series: [80, 22, 46, 40, 60, 70, 53, 60, 45, 55],
    linecolor: "#53B002",
  },
  {
    player: { name: "Theo Feron", photo: "./images/profilePhoto2.png" },
    club: "Frozen Stompers",
    tokenName: "SPM-THEOFERON",
    change: "+2.17",
    volume: "$6,589,804",
    price: "$2.00",
    series: [80, 22, 46, 40, 60, 70, 53, 60, 45, 55],
    linecolor: "#53B002",
  },
  {
    player: { name: "Rowan Reed", photo: "./images/profilePhoto3.png" },
    club: "Excellent Generals.",
    tokenName: "SPM-ROWANREED",
    change: "0.00",
    volume: "$3,748,011",
    price: "$1.90",
    series: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
    linecolor: "#6D7C89",
  },
  {
    player: { name: "Fred Fox", photo: "./images/profilePhoto4.png" },
    club: "Strong Titans",
    tokenName: "SPM-FREDFOX",
    change: "+1.05",
    volume: "$34,897,101",
    price: "$1.60",
    series: [80, 22, 46, 40, 60, 70, 53, 60, 45, 55],
    linecolor: "#F15645",
  },
  {
    player: { name: "Paul Cole", photo: "./images/profilePhoto5.png" },
    club: "Striped Devils",
    tokenName: "SPM-PAULCOLE",
    change: "+1.05",
    volume: "$5,757,926",
    price: "$0.85",
    series: [80, 22, 46, 40, 60, 70, 53, 60, 45, 55],
    linecolor: "#F15645",
  },
];

const options = (series: any, linecolor: any) => {
  return {
    chart: {
      backgroundColor: "transparent",
      type: "areaspline",
      width: 100,
      height: 55,
    },
    plotOptions: {
      series: {
        fillColor: {
          linearGradient: [0, 0, 0, 30],
          stops: [
            [0, "rgb(188, 216, 255)"],
            [1, "rgba(255, 255, 255)"],
          ],
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        data: series,

        lineWidth: 1.5,
        lineColor: linecolor,
        marker: {
          enabled: false,
        },
      },
    ],
    yAxis: [
      {
        visible: false,
      },
    ],
    xAxis: [
      {
        visible: false,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
  };
};

export const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  if (completed) {
    return "Sold Out";
  } else {
    return (
      <p className="text-[14px] leading-[17px] font-medium text-[#0089EA]">
        {days}d {hours}h {minutes}m {seconds}s
      </p>
    );
  }
};

export default function MobileMarkets() {
  const { t } = useTranslation();

  return (
    <div className="container lg:hidden" id="markets">
      <h3 className="text-[20px] leading-[20px] font-semibold tracking-[-0.33px] text-[#0089EA] mb-[10px]">
        {t("Initial_Supplies")}
      </h3>

      {rows.map((row) => (
        <div
          key={row.player.name}
          className="flex items-center gap-[15px] border-b-[rgba(172, 192, 206, 0.3)] border-b-[1px] border-b-[solid] py-[12px]"
          style={{
            fontFamily: "Inter",
          }}
        >
          <img
            src={row.player.photo}
            alt={row.player.name}
            className=" w-[50px] h-[50px]"
          />
          <div className="flex flex-col gap-[4px]">
            <span className="text-[16px] leading-[19px] font-medium text-[#2E3A4B]">
              {row.player.name}
            </span>
            <span className="text-[14px] leading-[17px] font-medium text-[#2E3A4B]">
              {row.club}
            </span>
            <Countdown date={row.remaining} renderer={renderer} />
          </div>
          <div className="ml-auto flex flex-col gap-[4px] items-end ">
            <span className="text-[16px] leading-[19px] font-medium text-[#2E3A4B]">
              {row.price}
            </span>
            <span className="text-[14px] leading-[17px] font-medium  text-[rgba(46,58,75,0.75)] text-right">
              {row.soldTotalSupply}/10M
              <LinearProgress
                variant="determinate"
                value={row.value}
                sx={{
                  width: "150px",
                  mt: "6px",
                  "&.MuiLinearProgress-root": {
                    backgroundColor: "rgba(172, 192, 206, 0.3)",
                    borderRadius: "8px",
                  },
                  "&.MuiLinearProgress-bar1": {
                    bacgroundColor: "#0089EA",
                  },
                }}
              />
            </span>
          </div>
        </div>
      ))}

      <div className="flex flex-col items-center gap-[18px] my-[20px]">
        <Button
          variant="contained"
          sx={{
            fontFamily: "Catamaran",
            width: 345,
            height: "48px",
            fontSize: 17,
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.1px",
            textTransform: "none",
            background: "#0089EA",
            borderRadius: "5px",
            boxShadow: "none",
          }}
        >
          {t("Play_Trade")}
        </Button>

        <div className="flex gap-[9px] items-center justify-center">
          <img
            src="./images/GooglePlay.png"
            alt="header image"
            className="w-[157px] h-[45px]"
          />
          <img
            src="./images/AppStore.png"
            alt="header image"
            className="w-[157px] h-[45px]"
          />
        </div>
      </div>
      <h3 className="text-[20px] leading-[20px] font-semibold tracking-[-0.33px] text-[#0089EA] mb-[10px]">
        {t("Marketplace_Tokens")}
      </h3>

      {rows2.map((row) => (
        <div
          key={row.player.name}
          className="flex items-center gap-[15px] border-b-[rgba(172, 192, 206, 0.3)] border-b-[1px] border-b-[solid] py-[12px]"
          style={{
            fontFamily: "Inter",
          }}
        >
          <img
            src={row.player.photo}
            alt={row.player.name}
            className=" w-[50px] h-[50px]"
          />
          <div className="flex flex-col gap-[4px]">
            <span className="text-[16px] leading-[19px] font-medium text-[#2E3A4B]">
              {row.player.name}
            </span>
            <span className="text-[14px] leading-[17px] font-medium text-[#2E3A4B]">
              {row.club}
            </span>

            <span className="text-[14px] leading-[17px] font-medium text-[#53B002]">
              {row.change}%
            </span>
          </div>
          <div className="ml-auto flex flex-col gap-[4px] items-end mb-[-12px]">
            <span className="text-[16px] leading-[19px] font-medium text-[#2E3A4B]">
              {row.price}
            </span>
            <div className="flex flex-col justify-end items-end">
              <span className="text-[14px] leading-[17px] font-medium  text-[rgba(46,58,75,0.75)] text-right">
                Vol. {row.volume}/24h
              </span>

              <HighchartsReact
                highcharts={Highcharts}
                options={options(row.series, row.linecolor)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
