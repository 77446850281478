import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, LinearProgress } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

const rows = [
  {
    player: { name: "Alec Harrison", photo: "./images/profilePhoto.png" },
    club: "Galactica F.C.",
    tokenName: "SPM-ALECHARRISON",
    soldTotalSupply: "7,198,255",

    value: 71,
    remaining: new Date(2023, 1),
    price: "$1.25",
  },
  {
    player: { name: "Theo Feron", photo: "./images/profilePhoto2.png" },
    club: "Frozen Stompers",
    tokenName: "SPM-THEOFERON",
    soldTotalSupply: "3,962,866",

    value: 39,
    remaining: new Date(2023, 1),
    price: "$2.00",
  },
  {
    player: { name: "Rowan Reed", photo: "./images/profilePhoto3.png" },
    club: "Excellent Generals",
    tokenName: "SPM-ROWANREED",
    soldTotalSupply: "9,015,121",

    value: 90,
    remaining: new Date(2023, 1),
    price: "$1.90",
  },
  {
    player: { name: "Fred Fox", photo: "./images/profilePhoto4.png" },
    club: "Strong Titans",
    tokenName: "SPM-FREDFOX",
    soldTotalSupply: "6,766,924",

    value: 67,
    remaining: new Date(2023, 1),
    price: "$1.60",
  },
  {
    player: { name: "Paul Cole", photo: "./images/profilePhoto5.png" },
    club: "Striped Devils",
    tokenName: "SPM-PAULCOLE",
    soldTotalSupply: "3,094,450",

    value: 30,
    remaining: new Date(2023, 1),
    price: "$0.85",
  },
];

const rows2 = [
  {
    player: { name: "Alec Harrison", photo: "./images/profilePhoto.png" },
    club: "Galactica F.C.",
    tokenName: "SPM-ALECHARRISON",
    change: "+6.33",
    volume: "$5,757,926",
    price: "$1.25",
    series: [80, 22, 46, 40, 60, 70, 53, 60, 45, 55],
    linecolor: "#53B002",
  },
  {
    player: { name: "Theo Feron", photo: "./images/profilePhoto2.png" },
    club: "Frozen Stompers",
    tokenName: "SPM-THEOFERON",
    change: "+2.17",
    volume: "$6,589,804",
    price: "$2.00",
    series: [80, 22, 46, 40, 60, 70, 53, 60, 45, 55],
    linecolor: "#53B002",
  },
  {
    player: { name: "Rowan Reed", photo: "./images/profilePhoto3.png" },
    club: "Excellent Generals.",
    tokenName: "SPM-ROWANREED",
    change: "0.00",
    volume: "$3,748,011",
    price: "$1.90",
    series: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
    linecolor: "#6D7C89",
  },
  {
    player: { name: "Fred Fox", photo: "./images/profilePhoto4.png" },
    club: "Strong Titans",
    tokenName: "SPM-FREDFOX",
    change: "+1.05",
    volume: "$34,897,101",
    price: "$1.60",
    series: [80, 22, 46, 40, 60, 70, 53, 60, 45, 55],
    linecolor: "#F15645",
  },
  {
    player: { name: "Paul Cole", photo: "./images/profilePhoto5.png" },
    club: "Striped Devils",
    tokenName: "SPM-PAULCOLE",
    change: "+1.05",
    volume: "$5,757,926",
    price: "$0.85",
    series: [80, 22, 46, 40, 60, 70, 53, 60, 45, 55],
    linecolor: "#F15645",
  },
];

const options = (series: any, linecolor: any) => {
  return {
    chart: {
      backgroundColor: "transparent",
      type: "areaspline",
      width: 100,
      height: 55,
    },
    plotOptions: {
      series: {
        fillColor: {
          linearGradient: [0, 0, 0, 30],
          stops: [
            [0, "rgb(188, 216, 255)"],
            [1, "rgba(255, 255, 255)"],
          ],
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        data: series,

        lineWidth: 1.5,
        lineColor: linecolor,
        marker: {
          enabled: false,
        },
      },
    ],
    yAxis: [
      {
        visible: false,
      },
    ],
    xAxis: [
      {
        visible: false,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
  };
};

export const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  if (completed) {
    return "Sold Out";
  } else {
    return (
      <p className="min-w-[150px] text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
        {days}d {hours}h {minutes}m {seconds}s
      </p>
    );
  }
};

export default function Markets() {
  const { t } = useTranslation();
  return (
    <div className="container lgx:hidden" id="markets">
      <h3 className="text-[20px] leading-[20px] font-semibold tracking-[-0.33px] text-[#0089EA] mb-[20px]">
        {t("Initial_Supplies")}
      </h3>

      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            padding: "16px 10px",
            borderBottomColor: "rgba(172, 192, 206, 0.3)",
            fontFamily: "Inter",
          },
          "& .MuiTableCell-head": {
            backgroundColor: "#ACC0CE0D",
            fontFamily: "Lato",
          },
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Player")}
              </span>
            </TableCell>
            <TableCell align="left">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Club")}
              </span>
            </TableCell>
            <TableCell align="left">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Token_Name")}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Sold/Total_Supply")}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Remaining")}
              </span>
            </TableCell>
            <TableCell align="center">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Price")}
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.player.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="left">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={row.player.photo}
                    alt={row.player.name}
                    className="mr-[10px] w-[35px] h-[35px]"
                  />
                  <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                    {row.player.name}
                  </span>
                </Box>
              </TableCell>
              <TableCell align="left">
                <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                  {row.club}
                </span>
              </TableCell>
              <TableCell align="left">
                <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                  {row.tokenName}
                </span>
              </TableCell>
              <TableCell align="right">
                <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                  {row.soldTotalSupply}/10M
                  <LinearProgress
                    variant="determinate"
                    value={row.value}
                    sx={{
                      mt: "7px",
                      "&.MuiLinearProgress-root": {
                        backgroundColor: "rgba(172, 192, 206, 0.3)",
                        borderRadius: "8px",
                      },
                      "&.MuiLinearProgress-bar1": {
                        bacgroundColor: "#0089EA",
                      },
                    }}
                  />
                </span>
              </TableCell>
              <TableCell align="right">
                <Countdown date={row.remaining} renderer={renderer} />
              </TableCell>
              <TableCell align="center">
                <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                  {row.price}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h3 className="text-[20px] leading-[20px] font-semibold tracking-[-0.33px] text-[#0089EA] mb-[20px] mt-[60px]">
        {t("Marketplace_Tokens")}
      </h3>
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableCell-root": {
            padding: "16px 10px",
            borderBottomColor: "rgba(172, 192, 206, 0.3)",
            fontFamily: "Inter",
          },
          "& .MuiTableCell-head": {
            backgroundColor: "#ACC0CE0D",
            fontFamily: "Lato",
          },
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Player")}
              </span>
            </TableCell>
            <TableCell align="left">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Club")}
              </span>
            </TableCell>
            <TableCell align="left">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Token_Name")}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Change")}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Volume")}
              </span>
            </TableCell>
            <TableCell align="center">
              <span className="text-[15px] leading-[18px] font-bold tracking-[0px] text-[rgba(46,58,75,0.75)]">
                {t("Price")}
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows2.map((row) => (
            <TableRow
              key={row.player.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="left">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={row.player.photo}
                    alt={row.player.name}
                    className="mr-[10px] w-[35px] h-[35px]"
                  />
                  <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                    {row.player.name}
                  </span>
                </Box>
              </TableCell>
              <TableCell align="left">
                <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                  {row.club}
                </span>
              </TableCell>
              <TableCell align="left">
                <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                  {row.tokenName}
                </span>
              </TableCell>
              <TableCell align="right">
                <div className="flex items-center justify-end my-[-10px]">
                  <span
                    className={`text-[15px] leading-[18px] font-medium tracking-[0px] mr-[15px]`}
                    style={{ color: row.linecolor }}
                  >
                    {row.change}%
                  </span>

                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options(row.series, row.linecolor)}
                  />
                </div>
              </TableCell>
              <TableCell align="right">
                <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                  {row.volume}24h
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="text-[15px] leading-[18px] font-medium tracking-[0px] text-[#2E3A4B]">
                  {row.price}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
