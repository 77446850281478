import Layout from "../components/composite/Layout";
import Banner from "../components/view/Banner";
import Contact from "../components/view/Contact";
import MobileContact from "../components/view/MobileContact";
import FAQ from "../components/view/FAQ";
import Features from "../components/view/Features";
import Markets from "../components/view/Markets";
import MobileMarkets from "../components/view/MobileMarkets";
import Partners from "../components/view/Partners";
import WhatIsSportimec from "../components/view/WhatIsSportimec";

export default function Home() {
  return (
    <Layout>
      <Banner />
      <div className="mt-[-80px] lgx:mt-[-30px]">
        <img
          src="./images/HomeImage.png"
          alt="home image"
          className="container  z-[-1] relative"
        />
      </div>
      <div className="mt-[-100px]">
        <WhatIsSportimec />
      </div>
      <div className="mt-[110px]">
        <Markets />
        <MobileMarkets />
      </div>
      <div className="mt-[110px]">
        <Features />
      </div>
      <div className="mt-[125px]">
        <FAQ />
      </div>
      <div className="mt-[125px]">
        <Partners />
      </div>
      <div className="mt-[118px] mb-[45px]">
        <Contact />
        <MobileContact />
      </div>
    </Layout>
  );
}
