import { Button } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LanguageSelector from "../ui/languageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";

export default function MobileHeader() {
  const [menu, setmenu] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const handleLocation = (hash: string) => {
    if (hash.slice(1) === location.hash) {
      return "text-[#0089EA]";
    }
    return "text-[#2E3A4B]";
  };

  const links = [
    { name: t("What_Is_Sportimec?"), link: "/#whatIsSportimec" },
    { name: t("Marketplace"), link: "/#marketPlace" },
    { name: t("Features"), link: "/#features" },
    { name: t("FAQ"), link: "/#faq" },
    { name: t("Partners"), link: "/#partners" },
    { name: t("Contact"), link: "/#contact" },
  ];

  return (
    <div className="w-full lg:hidden z-10 sticky top-0">
      <div className="container py-[25px] flex justify-between items-center bg-white">
        <HashLink onClick={() => setmenu(false)} smooth to="/#">
          <img
            src="./images/sportimec-logo.png"
            alt="logo"
            className="w-[165px] h-[74px]"
          />
        </HashLink>
        {!menu ? (
          <img
            src="./images/Hamburger.svg"
            alt="hamburger"
            onClick={() => setmenu(true)}
            className="cursor-pointer"
          />
        ) : (
          <img
            src="./images/Close.svg"
            alt="close"
            onClick={() => setmenu(false)}
            className="cursor-pointer"
          />
        )}
      </div>
      {menu && (
        <ul className="flex gap-[8x] flex-col px-[25px] py-[30px] z-100 bg-white w-full sticky top-0 shadow-md">
          {links.map(({ name, link }) => (
            <li
              key={name}
              className={`${handleLocation(
                link
              )} text-[17px] font-medium leading-[28px] tracking-[0.1px]`}
            >
              <HashLink onClick={() => setmenu(false)} smooth to={link}>
                {name}
              </HashLink>
            </li>
          ))}
          <li className="text-[#2E3A4B] text-[17px] font-medium leading-[28px] tracking-[0.1px]">
            <LanguageSelector />
          </li>
        </ul>
      )}
    </div>
  );
}
