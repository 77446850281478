import { useEffect } from "react";
import Layout from "../components/composite/Layout";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div
        className="shadow-2xl  mx-[auto] my-[5%] p-6 rounded-md lgx:mx-[5%]"
        style={{
          fontFamily: "Poppins",
          maxWidth: 800,
        }}
      >
        <h1 className=" font-semibold text-[18px] text-[rgba(45,51,57,0.9)] mb-[15px] mt-[2px]">
          KULLANICI SÖZLEŞMESİ
        </h1>
        <div className=" text-[rgba(45,51,57,0.9)] text-[13px]">
          MADDE 1 – TARAFLAR İşbu Defimec Kullanıcı Sözleşmesi (“Sözleşme”)
          Defimec Blokzincir Teknolojileri Yazılım ve Sanayi Ticaret Anonim
          Şirketi (“Defimec”) ile Defimec’e ait web siteleri, mobil uygulamaları
          veya Defimec tarafından geliştirilen, yayınlanan veya işletilen
          herhangi bir mecra ve uygulama üzerinden üye olarak, platformlarda
          sunulan içerik, ürün ve hizmetleri kullanan gerçek kişi
          (“Kullanıcı” veya “siz”) arasında geçerli olmak üzere düzenlenmiştir.
          MADDE 2 – TANIMLAR 2.1. Kullanıcı(lar): Bu Sözleşme’yi kabul ederek
          Defimec’e ait web siteleri, mobil uygulamaları veya diğer platformlar
          (birlikte “Platformlar”) üzerinden üye olan ve Defimec Platformlarını
          kullanan gerçek kişidir. 2.2 Kripto Varlık: Dağıtık muhasebe defteri
          işlevi gören blokzincir üzerinde, işlemleri güvenceye almak için
          kriptografi adı verilen şifreleme yöntemini kullanan, nakde alternatif
          bir değişim aracı olarak tasarlanmış, elektronik para niteliğinde
          olmayan bir dijital varlık ve sanal unsurdur. 2.3 Sportimec:
          Sportimec, Kullanıcıların hayranı olduğu sporcuların gerçek hayat
          piyasa değerlerine endeksli tokenlarının akıllı kontratlar vasıtası
          ile arz edildiği, oyunlaştırdığı ve takas ettiği blok zincir tabanlı
          menajerlik oyunu platformudur. Kullanıcılar tokenların oyuncu
          performansına bağlı olarak oluşan  piyasa değerine karşılık çeşitli
          ödül ve promosyonlar kazanırlar. Sportimec sistemiyle spor kulüpleri
          oyuncu transfer ücretlerinin tamamını, ulusal ve uluslararası spor
          hukuku, sermaye piyasası hukuku ve bankacılık hukuku kurallarına uygun
          şekilde temin edebilmektedir. 2.4. Sporcu Piyasa Değeri: Sporcunun
          istaiksel datalarıne ve performans verilerine dayandırlarak yapay zeka
          algoritmaları ile hesaplanan pazar değrini temsil eder. Platform
          üzerindeki kripto varlık türü, arz ve talep miktarı, fiyatı ve diğer
          tüm unsurları Sporcu Piyasa değeri’ne bağlı olarak değişiklik
          gösterir. 2.6. Startimec: Startup girişimlerinin ihtiyacı olan
          desteği, ortaklık hakkı ve hisse payı vermeksizin ERC-20 türü akıllı
          kontratlarla oluşturulan Token’lar aracılığı ile elde edebildiği
          kripto varlık hizmet platformudur. 2.8 Fanimec : Geniş hayran
          kitlelerine sahip kişiler ve kurumlar ile hayranları, takipçileri,
          üyeleri arasında düzenlenen çeşitli özel etkinliklerle etkileşim
          yaratarak deneyimler kazandırmak amacıyla üretilen Fan Tokenlar’ın
          arzının gerçekleştiği kripto varlık hizmet platformudur. 2.9 Gizlilik
          Politikası: Defimec’e ait ürün ve hizmetlerden faydalanan gerçek kişi
          Kullanıcı’nın kişisel verilerine ve çerez kullanımına dair genel
          gizlilik hükümlülüklerinin yer aldığı, Kullanıcı’nın erişimine açık
          metni belirtir. MADDE 3 – SÖZLEŞMENİN AMACI Bu Sözleşme, Kullanıcı’nın
          Defimec tarafından sağlanan ürün ve hizmetlerden faydalanmasına
          ilişkin koşul ve şartlar ile ilgili olarak, Tarafların hak ve
          yükümlülüklerinin belirlenmesi amacıyla akdedilmektedir. Kullanıcı,
          Defimec’in hizmetlerini kullanmakla bu Sözleşme’nin hüküm ve
          şartlarını kabul etmiş sayılır. MADDE 4 – GENEL ESASLAR 4.1.
          Kullanıcı, Sportimec’te üretilip arz edilen Fan Token’ları edinmenin;
          ortaklık, hissedarlık, alacaklılık, lisans teminat veya buna denk
          herhangi bir hak tesis etmeyeceğini ve gelecek dönem karına katılma,
          fikri mülkiyet hakkı veya Defimec, spor kulüpleri veya onların bağlı
          ortaklıklarına herhangi bir şekilde iştirak etmeye imkân tanıyan
          herhangi bir hak oluşturmayacağını kabul eder. Kullanıcı’ya sunulan
          kripto varlıklar Oyuncu’ya dair üçüncü taraf mülkiyet hakkı vermemekle
          beraber hiçbir şekilde bir sermaye piyasası aracı veya bir ödeme aracı
          olarak nitelendirilemez. Bu Sözleşme, bir yatırım sözleşmesi değildir.
          4.3. Kullanıcı, Defimec’in desteklediği kripto varlık cüzdan
          türlerinden biri veya Defimec tarafından sağlanan soğuk cüzdanlar
          (cold storage) aracılığıyla tercihine göre merkezi veya merkeziyetsiz
          finans ekosistemlerinde alım, satım ve transfer işlemi yapabilir. 4.4.
          Defimec, mevcut diğer kripto varlık hizmet sağlayıcı Platformlar’ından
          bağımsız bir şirkettir. Bu Kullanıcı Sözleşmesi’nde belirtilenler
          dışında hiçbir internet platformunun ortağı, kurucusu veya temsilcisi
          değildir. Hiçbir şirketle ortak çalışma yürütmemektedir. Dolayısıyla
          Kullanıcı, benzer Platformlar üzerinden yaşadığı mağduriyetten dolayı
          Defimec’i sorumlu tutamaz. 4.5. Defimec’e ait hiçbir Platform’da
          “yardım toplama faaliyeti” gerçekleştirilmemektedir. Kullanıcı’nın
          Platformlar vasıtasıyla göndereceği itibari para tutarları herhangi
          bir şirketin sermayesini oluşturmaz. Platformlar’da yapılan işlemler,
          şirket kurma prosedürü veya mevcut bir şirketin sermayesini arttırma
          faaliyeti olarak nitelendirilemez. 4.6. Kullanıcı tarafından Platform
          üzerinde yapılan her alım-satım ve itibari para çekme-yatırma
          işlemleri, Defimec tarafından belirlenen ve www.defimec.com adresi
          üzerinden duyurularak sürekli olarak güncellenecek olan komisyon
          ücretine tabidir. Defimec, komisyon ücretini tek taraflı olarak
          belirleyeceği gibi, önceden duyurmak kaydıyla, değişiklik yapma
          hakkına da sahiptir. Değişiklikler Platformlar’da duyurulduğu andan
          itibaren geçerli olur. Defimec, komisyon ücretini Kullanıcı’nın
          gerçekleştirdiği işleme konu olan kripto varlık üzerinden tahsil
          edebileceği gibi, Kullanıcı’nın Platformlar üzerinde mevcut hesabından
          da tahsilat yapabilir. Kullanıcı’nın hesap bakiyeleri komisyon
          ücretinin ödenmesine müsait değil ise, komisyon bedeli transfer
          tutarından düşülebilir ya da transfer Platformlar tarafından
          reddedilebilir. Komisyon ücretleri Platform hizmet bedeli olup,
          kesinlikle (transfer iptal edilse dahi) iade edilmez. Kullanıcı, bu
          hususları işbu Sözleşme ile peşinen kabul eder. 4.7. Defimec
          Platformları’ndaki Token’ların, dünya çapında SEC (Securities Exchange
          Comission), UEFA, FIFA ve FIBA, WIPO; yerel anlamda SPK, BDDK, TFF ve
          diğer tüm ilgili kurumların düzenlemeleri ve kriterlerine uygun olarak
          üretilip arz edileceğini beyan eder. Token’lar asgari, azami uygunluk
          ve birden fazla değerleme kriterine tabi olarak arza konu edilir. Bu
          nedenle kamu hayatında sosyal, sportif, sanatsal veya başka biçimde
          rol oynayan her şahıs (sporcu, sosyal medya etkileyicisi, sanatçı,
          oyuncu, model ve benzeri) için herhangi bir türde Token üretilmesi
          mümkün değildir. 4.8. Startimec, Fanimec ve Sportimec üzerinde
          simsarlık, kredi sağlama veya herhangi bir finans kurumu hizmeti
          verilmez. 4.9. Defimec, aracılığıyla yapılan işlemlerden kaynaklanan
          tüm anlaşmazlıklarda nihai karar mercii olma hakkını saklı tutar.
          MADDE 5 – ÜYELİK 5.1. Kullanıcı olmak isteyen kişi, Platform’a üyelik
          esnasında talep edilen bilgileri doğru, güncel ve eksiksiz olmak
          kaydıyla Defimec’e temin etmelidir. Üyelik başvurusu Defimec
          tarafından Müşteri Tanıma (Know Your Customer veya kısaca “KYC”) ve
          Kara Para Aklamayı Önleme (Anti-Money Laundering veya kısaca “AML”)
          prosedürlerine göre değerlendirmeye alınır. Onaylanma işleminin
          tamamlanması ve Kullanıcı’ya bildirilmesi ile üyelik statüsü başlar.
          Bunun sonucunda Kullanıcı, işbu Sözleşme’de ve Platform’un ilgili
          yerlerinde belirtilen hak ve yükümlülüklere sahip olur.  5.2.
          Kullanıcı hesap bilgileri sayfası; Defimec Platformlarında yer alan
          çeşitli uygulama ve hizmetlerden yararlanılabilmek için gerekli
          işlemlerin gerçekleştirilebildiği, Kullanıcı tarafından kişisel ve
          uygulama bazında talep edilen bilgilerinin girildiği, sadece ilgili
          Kullanıcı’ya ait telefon numarası ve şifre ile erişilebilen
          Kullanıcı’ya özel sayfadır. 5.3. Kimlik tespiti prosedürleri ve hukuki
          otoritelerle belirlenmiş yasal yükümlülükleri gereği Defimec
          Kullanıcı’dan; kimlik bilgileri, ikametgah, mali durum ve/veya token
          arzları sırasında Fan Tokenlar’ı satın alma uygunluğunun ispati için
          ek belgeler ve bilgiler (ilgili belge ve bilgilerin varlığını
          kanıtlayan yazılı, görsel ve işitsel içerikler de dahil olmak üzere)
          sunulmasını talep etme hakkına sahiptir. 5.4. Defimec Platformları’nda
          bir hesap oluşturmak için kaydolduğunuzda, (i) 18 yaşında veya geçerli
          yasalar kapsamında bağlayıcı bir sözleşme imzalayabilecek kanuni
          sorumluluk yaşında olduğunuzu; (ii) gerçek kişi olarak bu şartları
          yerine getirmek üzere tam fiil ehliyetine ve yeterli yetkiye sahip
          olduğunuzu; (iii) Defimec Platformlarındaki bir hesabınızın daha önce
          askıya alınmamış veya kaldırılmamış olduğunu; (iv) halihazırda başka
          bir Defimec Platformu hesabınızın olmadığını; (vi) Defimec tarafından
          sağlanan hizmetleri kullanmanızın, kara para aklamayı önleme,
          yolsuzlukla mücadele ve terörle mücadele finansmanına ilişkin
          yönetmelikler, MASAK ve FATF Tavsiyeleri de dahil ancak bunlarla
          sınırlı olmamak üzere sizin için geçerli hiçbir yasa ve yönetmeliği
          ihlal etmeyeceğini ve (v) kripto varlıklarla işlem yapmanın
          yasaklanmış olduğu herhangi bir yargı yetkisi alanında ikamet
          etmediğinizi beyan ve taahhüt edersiniz. 5.5. Kullanıcı’nın Platform’a
          kaydolurken verdiği bilgilerin gerçeğe aykırı olması durumunda Defimec
          sözleşmeyi feshetme, Kullanıcı hesabını askıya alma ve engelleme gibi
          duruma uygun gerekli önlemleri alma hakkına sahiptir. Kullanıcı, beyan
          ettiği bilgilerin değişmesi halinde en kısa sürede Defimec’e
          değişikliği bildirecektir. Bu yükümlülüğü yerine getirilmemesinden
          kaynaklanan kayıp ve zararlardan Defimec sorumlu değildir. 5.6.
          Kullanıcı itibari para transferi yapacağı bankaya ait bilgilerin
          değiştiği durumda, itibari para çekim işlemini en erken 24 saat sonra
          yapabilir. 5.7. Kullanıcı, sözleşmeleri elektronik ortamda imzalayıp
          hesabını aktif hale getirdikten sonra hesabının onaylanmasıyla
          beraber, Türk Lirası ve kripto varlık yatırım işlemlerini yapmaya
          başlayabilir. Kullanıcı’nın bilgilerde değişiklik olduğu takdirde
          ancak, itibari para ve kripto varlık çekimi yapabilmek için 24 saat
          beklemek zorundadır. 5.8. Kullanıcı, Platformlara üye olurken verdiği
          bilgilerin doğruluğundan ve gizliliğinden, şifre ve adının
          korunmasından, başka kimselerle paylaşılmamasından bizzat sorumludur.
          Bu bilgilerin yetkisiz kişiler tarafından ele geçirilerek Defimec ürün
          ve hizmetlerinin kullanılması durumunda Kullanıcı aleyhine doğabilecek
          zararlara karşı Defimec hiçbir sorumluluk kabul etmemektedir. 5.9.
          Kullanıcı; hesabını, yükümlülüklerini ve haklarını üçüncü şahıslara
          devredemez, satamaz, kendi üyeliğini her ne ad altında olursa olsun
          başkasına kullandıramaz. Aksi takdirde Defimec Kullanıcı’nın hesabını
          iptal etme, durdurma, askıya alma hakkına sahiptir. Defimec’in bu
          haklarını kullanması nedeniyle Kullanıcı, tüm haklarından geri
          dönülmez bir şekilde vazgeçtiğini kabul eder. Bu madde kapsamında
          meydana gelen tüm zararlardan Kullanıcı sorumlu olup tüm cezai
          müeyyideler de Kullanıcı’ya aittir. 5.10. Dünyanın herhangi bir
          yerinde geçici ikamet/çalışma izni alan yabancı kimlik numarasına
          sahip Kullanıcı da ikamet/çalışma izni belgesi geçerlilik sürelerinin
          dolması durumunda; yeni ikamet/çalışma izni belgelerini 15 gün içinde
          Defimec’e ibraz etmekten sorumludur. Süresi içerisinde yapılmayan
          ibrazlardan ötürü Kullanıcı hesabının kaldırılması veya askıya
          alınmasından Defimec sorumlu tutulamaz. MADDE 6 – TARAFLARIN HAK VE
          YÜKÜMLÜLÜKLERİ 6.1. Kullanıcı, kripto varlıklara yatırım yapmanın
          belli başlı riskler içerdiğini, bu varlıklara ait fiyatların oldukça
          dalgalı bir seyir izlediğini, bu kapsamda gerek hukuki gerekse
          finansal getiri ve riskleri değerlendirme kabiliyetine sahip olduğunu
          kabul ve beyan eder. Kullanıcı, kripto varlıklara yatırım yapmanın
          risklerini anladığını, fiyat değişimlerinden oluşan kar ve zararlardan
          bizzat sorumlu olduğunu, Platform kullanımı sebebiyle kayıp veya
          zarara uğraması durumunda Defimec’in hiçbir sorumluluğu olmadığını ve
          Defimec’ten hiçbir hak talep edemeyeceğini kabul ve taahhüt eder. 6.2.
          Kullanıcı, Defimec’in herhangi bir yöneticisini, hissedarını,
          tedarikçilerini, temsilcilerini, çalışanını, danışmanını,
          iştiraklerini veya bağlı kuruluşunu Platformların kullanımı esnasında
          ortaya çıkabilecek herhangi bir olumsuz durumdan veya işbu Sözleşme
          kapsamında sunulan hizmetler veya yapılan işlemlerden kaynaklanan ya
          da bunların sonucu olarak ortaya çıkan kayıp ve/veya zararlardan
          sorumlu tutmayacağını taahhüt eder. 6.3. Kullanıcı, Platformlar
          üzerinden gerçekleştirdiği işlemlerin, doğrudan veya dolaylı olarak
          herhangi bir şekilde hukuka aykırılık teşkil etmediğini ve bir suç
          faaliyetiyle ilişkili olmadığını taahhüt eder. Defimec Platformları
          üzerinden erişim sağlanan içerik, ürün veya hizmet yahut niteliği
          gereği mümkünse satın alınan varlıklara dair bunların ne şekilde
          kullanıldığı, nereye ve ne amaçla transfer edildiği ve bir suçun ifası
          için kullanılıp kullanılmadığının takibi mümkün değildir. Kripto
          varlıkların kötüye kullanılmasından ötürü doğan ya da doğması muhtemel
          Kullanıcı ya da üçüncü kişilerin her türlü zarar ve kaybından Defimec
          hiçbir şekilde sorumlu tutulamaz. Defimec ürün, hizmet, site veya
          içeriklerinin kullanımı ile ilgili tüm masraflar, yasal sorumluluklar
          ve olası vergi mükellefiyetlerinden Kullanıcı bizzat sorumludur.
          6.4. Defimec, Kullanıcı’ya destek@defimec.com elektronik posta adresi
          üzerinden hizmetlerine ilişkin destek sağlamaktadır. Bu elektronik
          posta adresi dışında hiçbir yöntem ile Kullanıcı’ya destek hizmeti
          verilmemektedir. Destek hizmetlerinde Kullanıcı’ya şifre sorulmaz,
          kripto varlık göndermesi için adres bildirilmez. Kullanıcı bu madde
          hükümlerini kabul ederek Defimec’ten destek alacağını kabul ve beyan
          eder. Kullanıcı, bu maddeye aykırı bir durum ve talep ile karşılaştığı
          takdirde durumu derhal Defimec’e bildirerek, Defimec’in muhtemel
          dolandırıcılık işlemlerine karşı gerekli önlemleri alabilmesi için iş
          birliği içinde olacaktır. Bu husus bir tebliğ niteliğinde olup,
          Defimec bahsi geçen e-posta adresi dışındaki bir adresten gönderilmiş
          bir e-posta sebebiyle Kullanıcı’nın uğradığı ya da uğraması muhtemel
          zarar ve kayıplardan sorumlu tutulamaz. Kullanıcı kendi hesabının
          güvenliğini sağlamakla yükümlüdür. 6.5. Kullanıcı itibari para
          transfer işlemi gerçekleştirirken gerçek adı ile aynı isme kayıtlı
          olan banka hesabını kullanacağını kabul etmektedir. Kullanıcı’nın
          farklı isimlerle işlem yapması durumunda yaşanabilecek gecikmelerden
          ya da işlemin hiç gerçekleşmemesinden Kullanıcı sorumludur. Farklı
          isimlerle yapılan transferlerin iade süreci işbu Sözleşme’nin ayrılmaz
          parçası niteliğinde olan “Yardım Merkezi” bölümünde yer almaktadır. 
          Yine Kullanıcı ATM’ler üzerinden veya diğer para yatırma yöntemleri
          ile transfer yapması halinde doğacak gecikmelerden ya da transferin
          hiç gerçekleşmemesinden de kendisi sorumludur. Bu hususlara ilişkin
          prosedürler “Yardım Merkezi” bölümünde yer almaktadır. Kullanıcı’nın
          veya Kullanıcı ile doğrudan ya da dolaylı olarak ilişkilendirilen kişi
          ya da kişilerin bu hükümlere aykırı davranması, Sözleşme’nin tek
          taraflı ve haklı feshi nedeni oluşturur ve tüm hukuki ve cezai
          sorumluluk Kullanıcı’ya aittir. Defimec, bu kişiye ya da kişilere
          bundan sonra hesap açılmamasına karar verip bu kararını uygulayabilir.
          6.6. ATM’ler üzerinden kartsız bankacılık işlemleri ile para transferi
          gerçekleştirilmesi halinde eşleştirilecek hesaba veya kişiye ait
          referans kodu, isim veya unvan zorunlu olarak belirtilmelidir. Bu
          unsurlar yer almadan yapılan transfer/ödeme işlemlerinin tespit
          edilmesi halinde Defimec, söz konusu transferleri iade etme veya söz
          konusu tutarlar ile iletilecek emirleri kabul etmeme hakkına sahiptir.
          6.7. Defimec Platformlar’ı kaldıraçlı işlemlerin gerçekleştirilmesine
          imkan tanımaz. 6.8. Kullanıcı, Defimec marka adı ve/veya logosunu
          kullanarak Defimec’i lekeleyen, ticari itibarını zedeleyen ya da
          haksız rekabet yaratan paylaşımlar yapmayacağını taahhüt eder. Bu
          taahhüt her türlü yazılı ve görsel medya olmak üzere tüm sosyal medya
          mercilerini kapsamaktadır. Bu maddenin ihlalinin tespiti halinde,
          Defimec, önceden bildirimde bulunmaksızın Sözleşme’yi tek taraflı
          feshetme, Kullanıcı’nın hesabını engelleme, askıya alma ya da tamamen
          silme haklarını elinde bulundurur. Ek olarak Defimec, Kullanıcı’ya
          karşı bu maddenin ihlali nedeniyle her türlü tazmin hakkını
          kullanacaktır. Kullanıcı, bu maddenin uygulanması nedeniyle tüm itiraz
          ve talep haklarından peşinen feragat ettiğini kabul, beyan ve taahhüt
          eder. MADDE 7 – SORUMSUZLUK BEYANI 7.1. Defimec, Platformlar’da yer
          alan fiyatlama ve istatistikler de dahil hiçbir içerikten sorumlu
          değildir. Platform üzerindeki tüm veriler, analizler, raporlar,
          istatistikler herhangi bir düzenleme veya yönlendirme olmadan
          bilgileri otomatik işleme tabi tutmuş bir yazılım tarafından
          işlenmekte ve objektif olarak sunulmaktadır. Defimec tarafından
          sunulan her türlü haber ve raporlar; sadece bilgilendirmeye yöneliktir
          ve doğrudan çözüm, sonuç, hukuki görüş, politik ve sosyolojik
          araştırma bilgisi özelliği taşımamaktadır ve kesin doğruluğu garanti
          edilmemektedir. Veriler birbiriyle çelişkili veya tutarsız olabilir.
          Bu tür durumlarda Defimec hiçbir sorumluluk üstlenmez. 7.2. Defimec,
          kendisi tarafından sunulan içeriklerin Kullanıcı yoluyla herhangi bir
          kişi veya kuruluş tarafından elde edilecek sonuçlarla ilgili olarak,
          her türlü ticarete elverişlilik, performans, pazarlanabilirlik veya
          belirli bir amaca uygunluk garantileri de dahil olmak üzere açık ya
          dar örtülü, hiçbir şekilde garanti vermez. Tüm içerikler Kullanıcıya
          ‘olduğu gibi’ sunulmaktadır. Defimec içeriklerin kullanılmasından
          doğabilecek kar kayıplarından ya da menfi zararlar da dahil hiçbir
          zarardan sorumlu tutulamaz. Defimec tarafından sunulan içeriklerde,
          herhangi bir kişiye/ kuruma/ şirkete/ markaya yapılan atıf, bu
          kişilerin/ kurumların/ şirketlerin/ markaların piyasa değerine,
          çeşitli kriterlerdeki sıralamalarına, marka değerlerine etki yapacak
          veya hisse senetlerinin satın alınmasına, satılmasına veya elde
          tutulmasına yönelik bir tavsiye değildir. Genel itibariyle Defimec,
          Platformlar üzerinden hiçbir şekilde yatırım tavsiyesinde
          bulunmamaktadır. 7.3. Defimec hiçbir şekilde işlemlerin sonuçlanmasını
          ya da Deximec’e girilen emirlerin gerçekleşmesini garanti etmez.
          Fiyatlar tamamen işlem gerçekleştiren alıcı ve satıcı tarafların
          ilgili kripto varlığa yönelik arz – talep ilişkisine göre oluşur. En
          son gerçekleşen satış işlemi kripto varlıkların güncel fiyatını
          belirler. Blokzincir teknolojisinin doğal sonucu olarak Platformlar’da
          oluşan fiyatlara Defimec hiçbir şekilde müdahale etmez. Fiyatlarda
          ortaya çıkan değişiklikler ya da işlemlerin gerçekleşmemesi nedeniyle
          Kullanıcı’nın zarar veya kayıplara uğramasından ve doğan veya doğacak
          zararlardan Defimec sorumlu tutulamaz. 7.4. Defimec, platform iş akışı
          ve yazılım güvenliği açısından şüpheli işlem girişimi tespit ettiği
          Kullanıcıya ait hesap veya hesapları geçici olarak ya da kalıcı olarak
          alış satış emirlerine kapatmak, şüpheli işlem gerçekleştiren Kullanıcı
          hesaplarını askıya almak, dondurmak ya da geçici olarak kapatmak
          haklarına sahiptir. Defimec, adli ve/veya idari makamlardan gelecek el
          koyma (bloke) talepleri nedeniyle ve bu makamlardan bankalara gelecek
          talepler neticesinde bankaların bildirimi üzerine Kullanıcı’nın
          hesabındaki malvarlığına el koyma (bloke) işlemi uygulayabilir.
          Defimec tarafından dürüstlük ve iyi niyet kuralları çerçevesinde bu
          maddenin uygulanması nedeniyle, Defimec’in hukuki ve cezai
          sorumluluğuna gidilemez. 7.5. Platformlarda zaman zaman kesinti, hata
          veya başkaca bir teknik aksaklık meydana gelebilir. Özellikle kripto
          varlık alım satımı esnasında, Defimec tarafından öngörülemeyen ve
          önlenmesinin mümkün olmadığı sorunlar doğabilir. Defimec
          Platformlar’ın erişilebilir ve kullanılabilir olmasını hedeflemekle
          birlikte Platform’a erişimi sağlayan sistemlerin işlerliğini taahhüt
          etmemektedir. Defimec, olası bir sorunun çözülmesi için tüm gayretini
          gösterecektir. Kullanıcı, teknik arızalardan dolayı doğrudan ve
          dolaylı olarak doğan veya doğabilecek hiçbir zarardan Defimec’i
          sorumlu tutamaz. 7.6. Defimec, işlemlerin teknik hatalar sebebi ile
          ve/veya gerçekçi olmayan fiyatlardan gerçekleşmesi gibi hallerde,
          Platformlar’ı ve kullanılan sistemleri düzeltmek ve doğru çalışmasını
          sağlamak adına işlemleri iptal edebilir veya geri alabilir.
          Kullanıcı’nın, teknik arızadan haksız yarar sağladığı ya da
          Platform’un işleyişine etki edecek şekilde, örneğin siber saldırıyla,
          Platformlar’a müdahale ettiği tespit edilirse, sorumlu görülen
          Kullanıcı hesabı/hesapları bloke edilebilir ve/veya kaldırılabilir. Bu
          gibi durumlarda Defimec’in Platform dışına çıkartılan kripto varlık ya
          da itibari paranın iadesine ilişkin her türlü talep ve hukuki yola
          başvurma hakkı saklıdır. Olağanüstü durumlarda oluşacak bu gibi
          aksaklıklardan dolayı Platformlar’da meydana gelebilecek gecikme veya
          problemlerden, Defimec sorumlu tutulamaz. 7.7. Defimec tarafından
          Kullanıcı hesabında istemsiz olarak, sebepsiz zenginleşmeye yol açacak
          şekilde kripto varlık veya itibari para tanımlanması halinde durum
          gecikmeksizin elektronik posta, arama, SMS vb. yollarla Kullanıcı’ya
          bildirilir. Bildirime rağmen ilgili haksız tutar 1 iş günü içerisinde
          Kullanıcı tarafından iade edilmediği takdirde Defimec başka bir
          bildirimde bulunmaksızın Kullanıcı’nın hesabını kapatabilir, askıya
          alabilir ya da süresiz biçimde erişimi durdurulabilir. Yine bu halde,
          Defimec tarafından Kullanıcı’nın hesabındaki bakiye, hatalı yapılan
          transfer kadar eksiye düşürülebilir ve gerekli yasal yollara
          başvurulur. Defimec, bu işlem nedeniyle uğradığı doğrudan ya da
          dolaylı zararları tazmin hakkını saklı tutar. MADDE 8 – FİKRİ MÜLKİYET
          HAKLARI 8.1. Platform’da sunulan hizmete bağlı tüm servisler, yazılım
          kodları, kaynak ve nesne kodları, program ara yüzleri, alan adları,
          grafikler, videolar, algoritmalar, çizimler, modeller, tasarımlar, her
          türlü içerik, telif hakları, bağlantılı hak sahipliğine konu olanlar
          dahil olmak ve bunlarla sınırlı olmamak üzere diğer tüm fikri ve sınai
          hakların sahibi -üçüncü taraflardan sağlanan içerik ve uygulamalar
          hariç- münhasıran Defimec Blokzincir Teknolojileri Yazılım ve Sanayi
          Ticaret A.Ş.’dir. 8.2. Platformlar’da yer alan bilgiler hiçbir şekilde
          çoğaltılamaz, yayınlanamaz, kopyalanamaz, sunulamaz ve/veya
          aktarılamaz. Platformlar’ın bütünü veya bir kısmı diğer dijital
          mecralarda izinsiz olarak kullanılamaz ve tersine mühendislik işlemine
          tabi tutulamaz. MADDE 9 – HUKUKİ YAPTIRIMLAR 9.1. Kullanıcı’nın
          Defimec’e ait Platformları, aşağıda belirtilen yöntem ve amaçlar dahil
          ancak bunlarla sınırlı kalmamak kaydıyla, hukuka ve ahlaka aykırı
          şekilde kullandığının tespiti halinde Defimec, ilgili Kullanıcı’nın
          hesabını ihbarsız olarak sonlandırma veya askıya alma hakkına
          sahiptir. Defimec, bu hususların birinin veya birkaçının tespiti
          halinde Kullanıcı’nın hesabı veya hesaplarındaki malvarlıklarına
          bildirimde bulunmaksızın el koyabilir ve Kullanıcı hakkında dava,
          talep ve şikâyet hakkını saklı tutar. Platformların herhangi bir kişi
          adına veri tabanı, kayıt veya rehber yaratmak, kontrol etmek,
          güncellemek veya değiştirmek amacıyla kullanılması; Platformların
          bütünü veya bir bölümünde bozma, değiştirme veya tersine mühendislik
          işlemi yapılması kullanılması; Yanlış bilgiler veya başka bir kişinin
          bilgileri kullanılarak işlem yapılması; ikametgâh adresi, elektronik
          posta adresi, iletişim, ödeme veya hesap bilgileri de dahil yanlış
          veya yanıltıcı kişisel veriler kullanılmak suretiyle sahte üyelik
          hesapları oluşturulması ve bu hesapların Kullanım Sözleşmesi veya
          yürürlükteki mevzuatlara aykırı şekilde kullanılması, başka bir
          Kullanıcı’nın hesabının izinsiz kullanılması, başka birinin yerine
          geçilerek ya da yanlış bir isimle işlemlere taraf ya da katılımcı
          olunması; Platformların bot kullanarak tekrarlayan alış/satış emirleri
          girmek ve bu şekilde çok sayıda emir üretilmesine yol açarak haksız
          kazanç elde etmek amacıyla kullanılması; Defimec Platformlarının veri
          tabanına veya Platformlar üzerinde yer alan herhangi bir içeriğe virüs
          veya zarar verici herhangi başka bir zararlı yazılım yayılması;
          Platforma ait teknik sistemler üzerinde orantısız derecede büyük
          yüklemeler yaratacak ya da teknik işleyişe zarar verecek faaliyetlerde
          bulunulması, Defimec’in önceden yazılı izni alınmaksızın Platformları
          üzerinde robot, otomatik program, web crawler, örümcek, veri
          madenciliği yazılımı ve veri taraması (data crawling) gibi “web
          scraping” yazılım veya sistemlerin kullanılması ve bu şekilde
          Platformda yer alan herhangi bir içeriğin tamamının veya bir kısmının
          izinsiz kopyalanarak, yayınlanması veya kullanılması. MADDE 10 –
          HESABIN KAPATILMASI 10.1. Kullanıcı istediği tarihte herhangi bir
          neden ileri sürmeden hesabının tamamen kapatılmasını talep edebilir.
          Bu talep yalnızca destek@defimec.com üzerinden iletilebilir.
          Kullanıcı’nın hesabı talebi üzerine, talebin ulaştığı tarihten
          itibaren 30 gün içerisinde kapatılır. 10.2. Yasal düzenlemeler ve
          Defimec’in meşru menfaatleri gereğince hesabı kapatılan Kullanıcı’nın
          hesap işlem detayları ve verileri yasalarda öngörülen süreler boyunca
          Defimec tarafından saklanmaya devam edecektir.  MADDE 11 – GİZLİLİK
          POLİTİKASI VE KİŞİSEL VERİLERİN KORUNMASI 11.1. İşbu Sözleşme’nin
          Kullanıcı tarafından kabulü ve onaylanması halinde, Defimec, Kullanıcı
          bilgilerini, işbu Sözleşmenin ayrılmaz bir parçasını teşkil eden
          Gizlilik Politikası uyarınca işleyebilir. 11.2. Defimec, Kullanıcı’ya
          ait IP adreslerini, erişim sağladıkları cihazı ve modeli, işletim
          sistemleri ve tarayıcı bilgilerini tespit etmekte ve kayıt altına
          almaktadır. Kullanıcı, işbu Sözleşme’nin eki olan Gizlilik
          Politikası’nı kabul etmesi ile bu hususlarda Defimec’e açık rıza
          verdiğini de kabul eder. Defimec, topladığı bu bilgileri, detayları
          Gizlilik Politikası’nda yer aldığı üzere, Kullanıcılar’ını genel bir
          şekilde tanımlamak ve kapsamlı demografik bilgi toplamak, Kullanıcı ve
          sistem güvenliğini sağlamak, sahtecilik ile mücadele etmek ve kanuni
          yükümlülüklere uymak amacı ile kullanabilir. 11.3. Kullanıcılar’ın
          vermiş olduğu bilgiler Kullanıcı Sözleşmesi’nde belirtilen kurallar ve
          amaçlar dışında herhangi bir kapsamda kullanılmayacak, üçüncü
          şahıslarla paylaşılmayacaktır. 11.4. Defimec, Platform içerisinde
          başkaca sitelere veya uygulamalara bağlantı sağlayabilir. Anlaşmalı
          olduğu üçüncü tarafların reklamlarını ve/veya çeşitli hizmetlere
          ilişkin başvuru formlarını yayınlayabilir veya bu üçüncü kişi sitelere
          veya uygulamalara yönlendirebilir. Kullanıcı’nın bu şekilde eriştiği
          üçüncü taraf uygulamalarında veya sitelerinde bulunan gizlilik
          uygulaması ve politikaları ile barındırdıkları içeriğe ilişkin Defimec
          sorumlu değildir. 11.5. Defimec, aşağıda sayılan hallerde ise işbu
          gizlilik bildirimi hükümleri dışına çıkarak Kullanıcılar’a ait
          bilgileri üçüncü kişilere açıklayabilecektir. Bu durumlar: (i)
          Yürürlükteki mevzuatların gerektirdiği hallerde, (ii) Defimec’in,
          Kullanıcı ile arasındaki sözleşmelerin ifası ve bunların uygulamaya
          konulmalarıyla zorunlu olduğu hallerde, (iii) Yetkili idari ve/veya
          adli makamlar tarafından usuli yöntemine uygun olarak yürütülen bir
          araştırma veya soruşturma doğrultusunda Kullanıcı ile ilgili bilgi
          talep edilmesi hallerinde, (iv) Kullanıcı’nın haklarını veya
          güvenliklerini koruma amacıyla bilgi verilmesinin gerekli olduğu
          hallerde. 11.6. Defimec, kendisine verilen gizli bilgileri kesinlikle
          özel ve gizli tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak
          kabul ettiğini ve gizliliğin sağlanıp sürdürülmesi, gizli bilginin
          tamamının veya herhangi bir parçasının kamu alanına girmesini veya
          yetkisiz Kullanıcı’ya veya üçüncü bir kişiye açıklanmasını önleme
          gereği olan gerekli tüm tedbirleri almayı ve üzerine düşen tüm özeni
          tam olarak göstermeyi işbu bildirimle taahhüt etmektedir.
          11.7. Defimec işbu Kullanıcı Sözleşmesi’nin “Gizlilik ve Kişisel
          Verilerin Korunması” başlığı altındaki hükümleri uyarınca
          aşağıdakileri taahhüt eder: Kişisel verileri tüm dünyada ülkelerin
          yürürlükte olan kişisel verilerin korunması mevzuatlarına (KVKK, GDPR,
          CCPA, LGPD, PDPA, PIPL ve diğer tüm veri koruma mevzuatları) uygun
          şekilde toplamayı, işlemeyi ve muhafaza etmeyi, Kişisel verileri
          Taraflar arasındaki ilişkinin amacının gerçekleştirilmesi dışında,
          doğrudan veya dolaylı olarak başka hiçbir amaç için kullanmayacağını,
           Gizli Bilgi’yi yasal yükümlülükler hali hariç olmak üzere,
          Kullanıcı’nın onayı olmaksızın, üçüncü şahıs ya da kurumlara
          açıklamayacağını ve üçüncü şahıslarca kullanımına ve/veya kopya
          edilmesine izin vermeyeceğini, Gizli Bilgi’nin istihdam ettiği
          personel, vekiller, Taraflar adına hareket eden gerçek veya tüzel
          kişiler tarafından da korunacağını. MADDE 12 - MÜCBİR SEBEP 12.1.
          Kullanıcı, Defimec’in Türkiye’de ve/veya işlemlerin gerçekleştirildiği
          ülkelerde meydana gelebilecek ekonomik, sosyal veya politik
          karışıklıklar, Defimec’in faaliyetlerini önemli ölçüde etkileyebilecek
          bölgesel ve/veya küresel savaşlar, ulusal, bölgesel veya global
          ekonomik krizler ve dalgalanmalar, Türkiye’nin veya diğer ülkelerin
          kripto varlık ya da kambiyo rejimlerine, emtia ve kıymetli maden
          piyasalarına getirebilecekleri kısıtlamalar, doğal afetler ve
          olağandışı piyasa koşulları gibi nedenlerle veya Türkiye’de ve diğer
          ülkelerde yapılabilecek yasal düzenlemeler, kamu otoritelerinin
          tasarrufları ya da bunlardan farklı olarak önceden tahmin edilmesi ve
          gerekli ihtimam gösterilmek suretiyle üstesinden gelinmesi mümkün
          olmayan diğer olaylar ya da sebepler (“Mücbir Sebepler”) nedeniyle,
          işbu Sözleşmeden kaynaklanan yükümlülüklerini yerine
          getiremeyebileceğini ve bunlardan dolayı Kullanıcı’ya karşı herhangi
          bir sorumluluğunun olmayacağını kabul eder. 12.2. Defimec yukarıda yer
          verilen durumların varlığını tespit ettiği takdirde; tamamen kendi
          inisiyatifine dayanarak, bildirimde bulunmadan ve herhangi bir zamanda
          aşağıdaki önlemlerden bir veya daha fazlasını alabilir: Defimec’in
          makul olarak uygun gördüğü kapanış seviyesinde Kullanıcı’nın
          Deximec’te henüz gerçekleşmemiş emirlerinin iptal edilmesi. Ortaya
          çıkan durumun Defimec’in işbu Sözleşme’nin tüm şartlarını veya bazı
          şartlarını yerine getirmesini olanaksız kılması durumunda; söz konusu
          şart veya şartların uygulamasının askıya alınması veya değiştirilmesi.
          Gerekli gördüğü kripto varlığa ilişkin işlem saatinin ve/veya işlem
          kurallarının değiştirilmesi. Sayılan önlemlerin alınması
          hâlinde Defimec, doğrudan veya dolaylı olarak bu önlemlerden
          kaynaklanan herhangi bir zarar, kayıp, masraf ve giderden Kullanıcı’ya
          karşı sorumlu olmayacaktır. MADDE 13 - BÖLÜNEBİLİRLİK İşbu
          Sözleşme’nin herhangi bir hükmü, maddesi veya koşulunun herhangi bir
          zamanda ve herhangi bir sebeple tamamen veya kısmen geçersiz, yasadışı
          veya ifa edilemez olması veya sayılması halinde, bu durum, diğer
          hükümlerin, maddelerin veya koşulların geçerliliğini ve ifa
          kabiliyetini kesinlikle etkilemeyecektir. Bu durumda, Taraflar, kısmen
          veya tamamen geçersiz, yasadışı veya ifa edilemez olan veya sayılan
          hükümleri, Taraflar üzerinde aynı ekonomik ve hukuki etkilere sahip
          yeni, geçerli ve ifa edilebilir hükümlerle değiştirmek için iyi
          niyetle müzakerede bulunacaklardır. MADDE 14 - UYGULANACAK HUKUK VE
          YETKİ İşbu Sözleşme’den veya Platformlar’ın kullanımından doğan
          uyuşmazlıklarda Kullanıcı Sözleşmesi ve Türk Hukuku uygulanacak olup,
          uyuşmazlıkların çözümünde İstanbul Anadolu Mahkemeleri ve İcra
          Daireleri münhasıran yetkilidir. MADDE 15 – DİĞER HÜKÜMLER Defimec,
          işbu Sözleşme üzerinde, dilediği zaman ve gerekli gördüğü ölçü ve
          şekilde, değişiklik yapma yetki ve imkanına sahiptir. Yapılan tüm
          değişiklikler, bu Sözleşme’nin üzerinde belirtilen “Son Güncelleme
          Tarihi” itibariyle geçerli olacaktır. Değişiklik yapılması için,
          Kullanıcı’ya herhangi bir ihtar ya da ön bildirimde bulunulması
          yükümlülüğü olmadığı gibi Kullanıcı’nın yapılan değişikliklere itiraz
          hakkı da yoktur. Ancak Defimec, yapılacak değişikliklerin işleme
          alınmasından makul bir süre önce, Kullanıcı’ya bu değişikliği,
          Kullanıcı’nın seçtiği iletişim metodu (yoksa sadece e-posta yolu ile)
          duyurmak için gerekli özeni göstereceğini taahhüt eder. Yapılan
          değişikliği kabul etmek istemeyen Kullanıcı hesabını kapatabilir.
          Kullanıcı tarafından Platformlar’ın kullanımına devam edilmesi yapılan
          değişikliklerin kabulü anlamındadır. Değişikliklerden haberdar olmak
          adına bu Sözleşme’yi periyodik olarak kontrol etmenizi tavsiye ederiz.
          Platformlar her zaman işbu Kullanıcı Sözleşmesi’nin en son sürümünü
          yayınlayacaktır.
        </div>
      </div>
    </Layout>
  );
}
