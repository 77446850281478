import { useTranslation } from "react-i18next";

export default function Features() {
  const { t } = useTranslation();
  return (
    <div id="features" className="container flex flex-col gap-[110px]">
      <div className="flex items-center gap-[80px] lgx:flex-col-reverse lgx:gap-[50px]">
        <ul className="flex flex-col gap-[55px] lgx:gap-[40px]">
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/upToDate.svg"
              alt="up To Date"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Up_to_date_Player_Stats")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Easily_access")}
              </p>
            </div>
          </li>
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/transferHistory.svg"
              alt="transfer History"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Transfer_Histort_and_News")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("See_all_the_transfers")}
              </p>
            </div>
          </li>
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/jointheDiscussion.svg"
              alt="join the Discussion"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Join_the_Discussions")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Read_post_or_reply")}
              </p>
            </div>
          </li>
        </ul>
        <img
          src="./images/features.png"
          alt="Features"
          className="h-[577px] ml-[-200px] lgx:ml-[-60px] lgx:w-full lgx:h-[385px] lgx:object-contain"
        />
      </div>
      <div className="flex items-center gap-[30px] lgx:flex-col lgx:gap-[50px]">
        <img
          src="./images/features2.png"
          alt="Features 2"
          className="h-[548px] lgx:w-full lgx:h-[385px] lgx:object-contain"
        />
        <ul className="flex flex-col ml-[90px] gap-[55px] lgx:gap-[40px]">
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/fastDeposit.svg"
              alt="fast Deposit"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Fast_Deposit_and_Withdraw")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Make_all_your_asset")}
              </p>
            </div>
          </li>
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/easilyManage.svg"
              alt="easily Manage"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Easily_Manage_your_Wallet")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Easily_view_manage_and_transfer")}
              </p>
            </div>
          </li>
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/inviteAndWin.svg"
              alt="invite And Win"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Invite_and_Win_Together")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Share_your_referral_code")}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
