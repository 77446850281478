import { Button } from "@mui/material";
import Input from "./ui/input/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function LetsTalkAbout() {
  const {t} = useTranslation()
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    number: yup.string(),
    message: yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      number: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.resetForm();
      toast.success("Successfully Sent!", {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  return (
    <div className="lgx:relative">
      <ToastContainer />
      <div className="lgx:absolute lgx:bg-[#0089EA] lgx:h-[630px] lgx:w-full lgx:top-[50%] lgx:translate-y-[-50%] z-[-1]"></div>
      <form onSubmit={formik.handleSubmit} className="h-[919px]">
        <div
          className="px-[41px] pt-[98px] pb-[49px] flex flex-col w-[518px] gap-[25px] bg-white rounded-[15px] lgx:px-[22px] lgx:pb-[39px] lgx:w-auto lgx:mx-[15px]"
          style={{ boxShadow: "0px 51px 50px 6px rgba(0, 0, 0, 0.15)" }}
        >
          <div>
            <h1 className="text-[26px] leading-[43px] font-semibold tracking-[-0.5px] text-[#2E3A4B]">
              {t("LetsTalkAbout")}
            </h1>
            <p className="text-[17px] leading-[23.8px] text-[#6D7C89]">
              {t("ContactText")}
            </p>
          </div>
          <div className="flex flex-col gap-[18px]">
            <Input
              label={t("Name")}
              focus={false}
              value={formik.values.name}
              onChange={(event: any) =>
                formik.setFieldValue("name", event.target.value)
              }
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && t("NameRequired")}
            />
            <Input
              label={t("Mail")}
              focus={false}
              value={formik.values.email}
              onChange={(event: any) =>
                formik.setFieldValue("email", event.target.value)
              }
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && t("EmailRequired")}
            />
            <Input
              label={t("Number")}
              focus={false}
              value={formik.values.number}
              onChange={(event: any) =>
                formik.setFieldValue("number", event.target.value)
              }
              required={false}
            />
            <Input
              label={t("ContactMessage")}
              focus={false}
              multiline
              minRows={3}
              maxRows={3}
              value={formik.values.message}
              onChange={(event: any) =>
                formik.setFieldValue("message", event.target.value)
              }
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && t("MessageRequired")}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            sx={{
              fontFamily: "Catamaran",
              paddingY: "14px",
              fontSize: 17,
              fontWeight: 600,
              lineHeight: "19px",
              letterSpacing: "0.1px",
              textTransform: "none",
              background: "#0089EA",
              borderRadius: "5px",
            }}
            className="w-[230px] lgx:w-full"
          >
            Submit
          </Button>
          {/* <div className="flex items-center gap-[10px]">
            <img
              src="./images/checkFilled.svg"
              alt="check filled"
              className="w-[20px] h-[20px]"
            />
            <p className="text-[17px] leading-[24px] font-semibold text-[#0089EA]">
              Your message has been sent successfully, thank you!
            </p>
          </div> */}
        </div>
      </form>
    </div>
  );
}
