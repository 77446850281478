import Header from "./Header";
import Footer from "./Footer";
import { ReactNode } from "react";
import MobileHeader from "./MobileHeader";

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <Header />
      <MobileHeader />
      <div className="pt-[135px] lgx:pt-0">{children}</div>
      <Footer />
    </>
  );
}
