import { useTranslation } from "react-i18next";
import LetsTalkAbout from "../LetsTalkAbout";
import parse from 'html-react-parser'
export default function Contact() {
  const {t} = useTranslation()
  return (
    <div id="contact" className="lgx:hidden relative">
      <div
        style={{
          backgroundImage: "url(./images/contactBackground.jpg)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="absolute bg-[#0089EA] h-[600px] w-full top-[50%] translate-y-[-50%] z-[-1]"
      ></div>
      <div className="container flex items-center gap-[111px] lgx:flex-col lgx:gap-[25px]">
        <LetsTalkAbout />
        <div className="w-[500px]">
          <img
            src="./images/defimecApp.png"
            alt="Defimec App"
            className="w-[95px] h-[95px] mb-[21px]"
          />
          <p className="text-[40px] leading-[50px] tracking-[-0.5px] text-white mb-[15px]">
           {parse(t("DownloadNow"))}
          </p>
          <p className="text-[16px] leading-[24px] font-light text-white mb-[15px]">
           {parse(t("DownloadText"))}
          </p>
          <div className="flex gap-[9px] items-center">
            <img
              src="./images/GooglePlay.png"
              alt="header image"
              className="w-[157px] h-[45px]"
            />
            <img
              src="./images/AppStore.png"
              alt="header image"
              className="w-[157px] h-[45px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
