import { Button } from "@mui/material";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LanguageSelector from "../ui/languageSelector/LanguageSelector";

export default function Header() {
  const location = useLocation();
  const [scrolled, setscrolled] = useState(false);
  const { t } = useTranslation();

  const handleLocation = (hash: string) => {
    if (hash.slice(1) === location.hash) {
      return "text-[#0089EA]";
    }
    return "text-[#2E3A4B]";
  };

  const links = [
    { name: t("What_Is_Sportimec?"), link: "/#whatIsSportimec" },
    { name: t("Marketplace"), link: "/#marketPlace" },
    { name: t("Features"), link: "/#features" },
    { name: t("FAQ"), link: "/#faq" },
    { name: t("Partners"), link: "/#partners" },
    { name: t("Contact"), link: "/#contact" },
  ];

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setscrolled(true);
      } else {
        setscrolled(false);
      }
    };
  }, [window.scrollX]);

  return (
    <div
      style={scrolled ? { boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.2)" } : {}}
      className="w-full fixed bg-white transition-all z-[1] lgx:hidden"
    >
      <div
        className={`container ${
          scrolled ? "py-[8px]" : "py-[25px]"
        } flex justify-between items-center`}
      >
        <HashLink smooth to="/#">
          <img
            src="./images/sportimec-logo.png"
            alt="logo"
            className={`${
              scrolled ? "w-[95px] h-[42px]" : "w-[190px] h-[85px]"
            }`}
          />
        </HashLink>
        <ul className="flex gap-[35px] items-center xlx:gap-[15px]">
          {links.map(({ name, link }) => (
            <li
              key={name}
              className={`${handleLocation(
                link
              )} text-[17px] font-medium leading-[28px] tracking-[0.1px] hover:text-[#0089EA]`}
            >
              <HashLink smooth to={link}>
                {name}
              </HashLink>
            </li>
          ))}

          <li className="text-[#2E3A4B] text-[17px] font-medium leading-[28px] tracking-[0.1px]">
            <LanguageSelector />
          </li>
          <li>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Catamaran",
                width: 190,
                height: scrolled ? "34px" : "48px",
                fontSize: 17,
                fontWeight: 600,
                lineHeight: "28px",
                letterSpacing: "0.1px",
                textTransform: "none",
                background: "#0089EA",
                borderRadius: "5px",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#0089EA",
                  boxShadow: "none",
                },
              }}
            >
              {t("Play_Trade")}
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
}
