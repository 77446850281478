import { Link } from "react-router-dom";
import JoinTheCommunity from "../JoinTheCommunity";

export default function Footer() {
  const projects = [
    { name: "sportimec", image: "./images/sportimec.png" },
    { name: "startimec", image: "./images/startimec.png" },
    { name: "fanimec", image: "./images/fanimec.png" },
    { name: "deximec", image: "./images/deximec.png" },
  ];
  return (
    <div className="w-full">
      <div className="container">
        <JoinTheCommunity />
        <div className="flex flex-col gap-[15px] items-center mt-[44px]">
          <a href="mailto:info@sportimec.com">
            <span className="text-[24px] leading-[39px] font-semibold tracking-[0.5px] text-[#6D7C89]">
              info@sportimec.com
            </span>
          </a>

          <div className="flex items-center gap-[35px] lgx:flex-wrap lgx:gap-x-[20px] lgx:gap-y-[6px] lgx:justify-center">
            <Link to="/privacy-policy">
              <span className="text-[16px] leading-[26px] tracking-[0] text-[#0089EA] lgx:text-[15px] lgx:leading-[24px]">
                Gizlilik Politikası
              </span>
            </Link>

            <a href="user-agreement">
              <span className="text-[16px] leading-[26px] tracking-[0] text-[#0089EA] lgx:text-[15px] lgx:leading-[24px]">
                Kullanıcı Sözleşmesi
              </span>
            </a>
            <Link to="/cookies-policy">
              <span className="text-[16px] leading-[26px] tracking-[0] text-[#0089EA] lgx:text-[15px] lgx:leading-[24px]">
                Çerez Politikası
              </span>
            </Link>

            <Link to="/personal-data-protection-law">
              <span className="text-[16px] leading-[26px] tracking-[0] text-[#0089EA] lgx:text-[15px] lgx:leading-[24px]">
                KVKK Aydınlatma Metni
              </span>
            </Link>
            <Link to="/personal-data-retention-and-disposal-policy">
              <span className="text-[16px] leading-[26px] tracking-[0] text-[#0089EA] lgx:text-[15px] lgx:leading-[24px]">
                Kişisel Verilerin Saklanma ve İmha Politikası
              </span>
            </Link>
          </div>
          <div>
            <p className="text-[16px] leading-[26px] tracking-[0] text-[#B4B6BA] text-center lgx:text-[13px] lgx:leading-[21px]">
              Marmara Teknokent, Gebze 41400 Kocaeli - Türkiye
            </p>
            <p className="text-[16px] leading-[26px] tracking-[0] text-[#B4B6BA] text-center lgx:text-[13px] lgx:leading-[21px]">
              © 2022 Sportimec by Defimec Blockchain Technologies. All Rights
              Reserved
            </p>
          </div>
          <p className="text-[16px] leading-[26px] tracking-[0] text-[#B4B6BA] text-center lgx:text-[13px] lgx:leading-[21px]">
            All images of the app on the website are for informational purposes
            and may not reflect the final graphics of the product. Before using
            crypto assets (tokens), you should consider; their value may rise or
            fall, they are not regulated in most countries and you may have to
            pay taxes on profits from their sale.
          </p>
        </div>
        <div className="mt-[49px] flex gap-[38px] items-end justify-center mb-[109px] lgx:mt-[30px] lgx:gap-[25px] lgx:flex-wrap">
          <img
            src="./images/defimec.png"
            alt="defimec"
            className="mb-[6px] object-contain h-[45px]"
          />
          {projects.map(({ name, image }) => (
            <img
              key={name}
              src={image}
              alt={name}
              className="h-[26px] object-contain"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
