import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import parse from 'html-react-parser'

export interface AccordionCardProps {
  title: string;
  description: string;
  setAccordionId: Function;
  id: number;
  openedId: number;
}

export default function AccordionCard({
  title,
  description,
  setAccordionId,
  id,
  openedId,
}: AccordionCardProps) {
  const [expanded, setexpanded] = useState(false);
  useEffect(() => {
    if (openedId !== id && openedId !== -1) setexpanded(false);
  }, [openedId]);
  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => {
          setexpanded(expanded);
          setAccordionId(id);
        }}
        sx={{
          boxShadow: "none",
          borderRadius: "0 !important",
          borderBottom: "1px solid rgba(172, 192, 206, 0.5)",
          "& .MuiAccordionSummary-content": { margin: "25px 0" },
          "& .MuiAccordionSummary-root": {
            padding: "0",
          },
          "& .MuiAccordionDetails-root": {
            padding: "0 0 25px 0",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "25px 0 20px 0",
          },
          ["@media (max-width:1024px)"]: {
            "& .MuiAccordionSummary-content": { margin: "15px 0" },
            "& .MuiAccordionSummary-root": {
              padding: "0",
            },
            "& .MuiAccordionDetails-root": {
              padding: "0 0 15px 0",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: "15px 0 10px 0",
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <img src="./images/collapse.svg" />
            ) : (
              <img src="./images/expandMore.svg" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h1 className="text-[26px] leading-[35px] font-medium tracking-[-0.5px] text-[#2E3A4B] lgx:text-[23px] lgx:leading-[30px]">
            {title}
          </h1>
        </AccordionSummary>
        <AccordionDetails>
          <p className="text-[17px] leading-[26px] tracking-[0px] text-[#2E3A4B]">
            {parse(description)}
          </p>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
