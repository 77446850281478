import { FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";

export interface InputProps {
  focus: boolean;
  error?: boolean;
  label: string;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  value?: any;
  onChange?: any;
  helperText?: string | false;
  required?: boolean;
}

export default function Input({
  focus,
  error,
  label,
  multiline,
  minRows,
  maxRows,
  value,
  onChange,
  helperText,
  required = true,
}: InputProps) {
  const [tempfocus, setfocus] = React.useState(focus);

  return (
    <FormControl
      focused={tempfocus}
      error={error}
      id={label}
      required={required}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "5px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#D5DFE7",
          borderWidth: "1px !important",
        },
        "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#0089EA",
          },
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
          padding: "11px 15px",
          fontFamily: "Catamaran",
        },
        "& .MuiInputBase-input": {
          fontSize: 18,
          fontWeight: 500,
          lineHeight: 1,
          letterSpacing: "0px",
        },

        "& .MuiFormLabel-root.Mui-focused": {
          color: "#0089EA",
        },
        "& .MuiFormLabel-root.Mui-error": {
          color: "#F15645",
        },
      }}
    >
      <FormLabel
        htmlFor={label}
        sx={{
          fontFamily: "Catamaran",
        }}
        className="text-[16px] font-medium leading-[26px] mb-[7px]"
      >
        {label}
      </FormLabel>
      <TextField
        value={value}
        error={error}
        id={label}
        onFocus={(e) => setfocus(true)}
        onBlur={(e) => setfocus(false)}
        onChange={(event) => onChange(event)}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        helperText={helperText}
      />
    </FormControl>
  );
}
