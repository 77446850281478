import { log } from "console";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event: any) => {
    i18n.changeLanguage(event.target.value);
    setfirst(event.target.value);
  };

  const [first, setfirst] = useState(i18n.language);
  console.log(first);
  console.log(first?.split("-")?.[0]);

  return (
    <div className="flex items-center cursor-pointer relative">
      <select
        onChange={changeLanguage}
        name="langs"
        id="langs"
        className="absolute opacity-0 w-full h-full"
        value={first?.split("-")?.[0]}
      >
        <option value="en">EN</option>
        <option value="tr">TR</option>
      </select>
      {first?.split("-")?.[0] === "en" ? "EN" : "TR"}
      <img src="./images/arrowDown.svg" alt="arrow" className="ml-[6px]" />
    </div>
  );
};

export default LanguageSelector;
