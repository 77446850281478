import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faqEN, faqTr } from "../../language/faq";
import AccordionCard from "../ui/accordion/AccordionCard";

export default function SimpleAccordion() {
  const { i18n } = useTranslation();
  const [language, setfirst] = useState(i18n.language?.split("-")?.[0]);
  const [data, setData] = useState(faqTr)

  useEffect(() => {
    if(i18n.language?.split("-")?.[0] === "tr"){
      setData(faqTr)
    }else {
      setData(faqEN)

    }
  },[i18n.language?.split("-")?.[0]])

  const [openedId, setAccordionId] = useState(-1);

  return (
    <div id="faq" className="container px-[25px]">
      {data?.map(({ title, description, id }) => (
        <AccordionCard
          key={id}
          title={title}
          description={description}
          setAccordionId={setAccordionId}
          id={id}
          openedId={openedId}
        />
      ))}
    </div>
  );
}
