import { Suspense, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import "./i18n";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserAgreement from "./pages/UserAgreement";
import CookiesPolicy from "./pages/CookiesPolicy";
import PersonalDataProtectionLaw from "./pages/PersonalDataProtectionLaw";
import PersonalDataRetentionandDisposalPolicy from "./pages/PersonalDataRetentionandDisposalPolicy";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Routes>
        <Route path="/user-agreement" element={<UserAgreement />} />
      </Routes>
      <Routes>
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
      </Routes>
      <Routes>
        <Route
          path="/personal-data-protection-law"
          element={<PersonalDataProtectionLaw />}
        />
      </Routes>
      <Routes>
        <Route
          path="/personal-data-retention-and-disposal-policy"
          element={<PersonalDataRetentionandDisposalPolicy />}
        />
      </Routes>
    </>
  );
}

export default App;
