import { useTranslation } from "react-i18next";

export default function WhatIsSportimec() {
  const { t } = useTranslation();
  return (
    <div id="whatIsSportimec" className="container flex flex-col gap-[110px]">
      <div className="flex items-center gap-[80px] lgx:flex-col-reverse lgx:gap-[50px]">
        <ul className="flex flex-col gap-[55px] lgx:gap-[40px]">
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/realPlayer.svg"
              alt="real player"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Real_Player_Tokenization")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Get_instant_access")}
              </p>
            </div>
          </li>
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/allBasedMarket.svg"
              alt="real player"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Al_Based_Market_Valuation")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Combining_the_real_performances")}
              </p>
            </div>
          </li>
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/tradeOnTrusted.svg"
              alt="real player"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Trade_on_Trusted_Blockchain_Networks")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Transfer_the_tokens")}
              </p>
            </div>
          </li>
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/earnOnTransfer.svg"
              alt="real player"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Earn_on_Valuation")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("Earn_rewards_and_promotions")}
              </p>
            </div>
          </li>
        </ul>
        <img
          src="./images/MockupPhones.png"
          alt="Mockup Phones"
          className="h-[518px] lgx:w-full lgx:h-[370px] lgx:object-contain"
        />
      </div>
      <div className="flex items-center gap-[30px] lgx:flex-col lgx:gap-[50px]">
        <img
          src="./images/MockupPhones3.png"
          alt="Mockup Phones 3"
          className="h-[548px] lgx:w-full lgx:h-[370px] lgx:object-contain"
        />
        <ul className="flex flex-col gap-[55px] lgx:gap-[40px]">
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/buyInInitial.svg"
              alt="buy In Initial"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Buy_in_Initial_Supply")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("All_player_tokens")}
              </p>
            </div>
          </li>
          <li className="flex items-center lgx:flex-col lgx:items-center">
            <img
              src="./images/tradeOn.svg"
              alt="trade on"
              className="mr-[30px] lgx:mr-0 lgx:mb-[25px]"
            />
            <div>
              <p className="text-[26px] leading-[35px] font-semibold tracking-[-0.5px] text-[#2E3A4B] mb-[5px] lgx:text-center lgx:text-[24px] lgx:leading-[30px]">
                {t("Trade_on_the_Marketplace")}
              </p>
              <p className="text-[17px] leading-[25px] text-[#2E3A4B] lgx:text-center lgx:text-[16px]">
                {t("After_the_completion")}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
