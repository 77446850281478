import { useEffect } from "react";
import Layout from "../components/composite/Layout";

export default function PersonalDataProtectionLaw() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div
        className="shadow-2xl  mx-[auto] my-[5%] p-6 rounded-md lgx:mx-[5%]"
        style={{
          fontFamily: "Poppins",
          maxWidth: 800,
        }}
      >
        <h1 className=" font-semibold text-[18px] text-[rgba(45,51,57,0.9)] mb-[15px] mt-[2px]">
          DEFİMEC KİŞİSEL VERİLERİN KORUNMASI AYDINLATMA METNİ{" "}
        </h1>
        <div className=" text-[rgba(45,51,57,0.9)] text-[13px]">
          Defimec Blokzincir Teknolojileri Yazılım ve Sanayi Ticaret Anonim
          Şirketi (“biz” veya “Defimec”) olarak 6698 sayılı Kişisel Verilerin
          Korunması Kanunu’nda (“KVKK”) tanımlı veri sorumlusu sıfatıyla
          işlediğimiz kişisel verilerinizin güvenliği ve gizliliğine önem
          veriyoruz. Bu nedenle sizi, kişisel verilerinizin toplanması,
          saklanması, aktarılması da dahil olmak üzere ancak bunlarla sınırlı
          olmaksızın işlenmesi; söz konusu işlemenin amacı, yöntemi ve hukuki
          sebepleri hakkında bilgilendirmek isteriz. Verilerin Toplanması
          Defimec tarafından sahip olunan veya işletilen mobil uygulamalar,
          http://defimec.com , ve diğer web adreslerimiz, diğer platformlar
          (birlikte “platform(lar)”) aracılığıyla sizlere sunulan herhangi bir
          ürün veya hizmeti (“hizmetler”) ya da platformlar üzerinden sağlanan
          diğer içerikleri kullanarak, Defimec ile paylaştığınız kişisel
          verilerinizin işlenmesine Defimec Gizlilik Politikası (“Politika”),
          KVKK, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli
          Sözleşmeler Yönetmeliği’nden doğan yükümlülüklerin ifası ve ilgili
          mevzuat uyarınca izin vermektesiniz. Defimec (i) platformlar’ı
          kullanırken gönüllü olarak paylaştığınız bilgileri ve (ii)
          platformlar’da yer alan hizmetleri kullanırken, Politika’da belirtilen
          amaçlara uygun şekilde otomatik olarak elde edilen bilgileri sadece
          yasaların izin verdiği ölçüde toplar. Verilerin Toplanma Amacı,
          Yöntemi ve Hukuki Sebepleri Şirketimiz tarafından Defimec
          platformlarını ziyaret etmenizle birlikte veya iş birliği içerisinde
          olduğumuz iş ortakları aracılığıyla başta trafik verileriniz olmak
          üzere aşağıda belirtilen kişisel verileriniz beraberinde açıklanan
          amaçlarla bağlantılı, sınırlı ve ölçülü bir şekilde elektronik olarak,
          otomatik ve otomatik olmayan yöntemlerle KVK mevzuatına uygun olarak
          Şirketimiz tarafından işlenmektedir. Aşağıda tabloda sıralanan
          verileriniz KVKK madde 5/2-a kanunlarda açıkça öngörülmesi (Türk
          Ticaret Kanunu, Sigortacılık Kanunu vs.); madde 5/2-c hizmetlerimizden
          faydalanabilmeniz için gerekli sözleşmelerin kurulması ve ifasıyla
          doğrudan doğruya ilgili olması, madde 5/2-f şirketimizin meşru
          menfaatleri ve madde 5/2-ç hukuki yükümlülüklerin yerine getirilmesi
          için zorunlu olması hukuki sebeplerine dayalı olarak açık rızanız
          aranmaksızın işlenebilir. Kişisel verilerinizi, ürünlerimizi ve
          hizmetlerimizi gereksinimlerinizi karşılayacak şekilde uyarlamak
          amacıyla, pazarlama, tüketici profilleri ve demografik araştırmalar
          için dahili istatistikler oluşturmak amacıyla kullanabiliriz. Amacımız
          kullanıcılarımızı daha iyi anlamak ve kullanıcılarımıza daha iyi
          hizmet vermektir.Yukarıda belirtilen kişisel bilgilerinizi, yurt içi
          veya yurt dışında soruşturmalar, kovuşturmalar veya soruşturmalarla
          bağlantılı olarak mahkeme, adli veya idari düzenleyici otoriteler gibi
          üçüncü kişiler ya da hukuki veya düzenleyici otoritelerce getirilen
          yükümlülüklere uymak için işleyebilir ve paylaşabiliriz. Söz konusu
          otoritelerce izin verildiği ölçüde ve bir suçun önlenmesi veya tespit
          edilmesine halel getirmedikçe, bu tür talepleri size yönlendirmek veya
          yanıt vermeden önce sizi bilgilendirmek için elimizden gelen gayreti
          göstereceğiz. Verilerinizin Depolanması ve Doğruluğu Defimec, sizin
          tarafınızdan sağlanan verileri güvenli bir şekilde depolar ve
          yürürlükteki mevzuatın gerektirdiği şekilde, paylaştığınız bilgilerin
          yetkisiz erişimini veya toplanmasını önlemek için gerekli tüm
          önlemleri alır. Hizmetlerimizden faydalanmak için ilgili üçüncü bir
          hizmet sağlayıcıya kişisel veya başka bir veri sağlamanız halinde, bu
          verinin sağlanması ya da işlemin gerçekleştirilmesi sonucu olarak
          ortaya çıkan bilgilerin doğruluğu, eksiksizliği ve diğer maddi veya
          manevi olmayan herhangi bir konu, kayıp, zarardan münhasıran sorumlu
          olduğunuzu kabul edersiniz. Güvenlik Düzeyi Defimec, kişisel
          bilgilere, bunların bütünlüğüne ve güvenliğinin korunmasına önem
          vermektedir. Bu nedenle, tarafınızca sağlanan verilerin
          değiştirilmemesini ve herhangi bir bilgiye yetkisiz kişilerce
          erişilmemesi için kişisel bilgilerinizin sıkı bir şekilde izlendiği
          yüksek güvenlikli veri tabanlarımızda güvenle saklanmasını sağlıyoruz.
          Bilgilerinizin Kiminle Paylaşıldığı Defimec olarak, hizmetlerimizi
          yerine getirmek için gerektirdiği ölçüde kişisel bilgilerinizi,
          tedarikçilerimiz, çözüm ortaklarımız ve yetkili kamu kurum ve
          kuruluşlarıyla paylaşmamız gerekmektedir. Kişisel verilerinizi, hukuki
          ve düzenleyici otoritelerce getirilen yükümlülükler kapsamında yetkili
          kamu kurumları ile; güvenlik, risk yönetimimizi geliştirmek ve
          kullanıcı deneyimini en üst düzeyde tutmak üzere kullanıcı istatistik
          raporları oluşturmak için istatistik hizmeti veren kuruluşlarla
          paylaşmaktayız. Hizmet sağlayıcımız olan harici web siteleri ve
          şirketler, kişisel olarak tanımlanabilir bilgilerinize
          platformlarımızdaki bağlantılar aracılığıyla erişebilirler. Bu gibi
          hallerde üçüncü taraflarca veri toplanması, işlenmesi, paylaşılması
          veya aktarımı sorumluluğumuzun dışındadır. Lütfen ilgili web siteleri
          ve şirketlerin gizlilik politikasına doğrudan başvurunuz. Çerezler
          Defimec Çerez Politikası’nda detaylı olarak belirttiğimiz şekilde,
          diğer birçok internet sitesi gibi biz de “çerezlerden”
          faydalanmaktayız. Çerezler bir internet sayfası sunucusu tarafından
          sabit sürücünüze iletilen küçük metin dosyalarıdır. Bu yolla, IP
          adresi, kullanılan tarayıcı, bilgisayarınızdaki işletim sistemi ve
          internet bağlantınız gibi belirli verileri otomatik olarak elde
          ederiz. Çerezler programları çalıştırmada veya bilgisayarınıza
          virüsler göndermede kullanılamaz. Çerezlerin içerdiği bilgilerle biz
          sadece navigasyonu kolaylaştırabilir ve internet sitelerimizin doğru
          görüntülenmesini sağlayabiliriz. Sizi farklı hizmetlerde tanımak,
          hizmetlerimiz üzerinde ve dışında ilgi alanlarınız hakkında bilgi
          sahibi olmak, deneyiminizi geliştirmek, güvenliği arttırmak,
          hizmetlerimizin kullanımını ve etkinliğini ölçmek amacıyla mobil
          uygulama tanımlayıcıları gibi çerez ve benzeri teknolojiler
          kullanırız. Tarayıcı ayarları ve diğer araçlar aracılığıyla çerezleri
          kontrol edebilirsiniz. Her durumda, topladığımız veriler sizin
          onayınız olmadan üçüncü şahıslarla paylaşılamaz. Elbette ki zorunlu
          çerezler hariç olmak üzere çerezler olmadan internet sitemizi her
          zaman görüntüleyebilirsiniz. İnternet tarayıcılarının varsayılan
          ayarları, çerezleri kabul edecek şekilde kuruludur. Tarayıcı
          ayarlarınız vasıtasıyla çerezlerin kullanımını her zaman
          durdurabilirsiniz. Bu ayarların nasıl değiştirileceğini öğrenmek için
          lütfen tarayıcınızın yardım fonksiyonunu kullanınız. Çerezlerin
          kullanımını durdurduğunuzda platformlarımızın belirli özelliklerinin
          çalışmayabileceğini lütfen unutmayınız. Kayıtların Yönetimi Kişisel
          verileriniz, işlenme amaçları için gerekli süreden fazla tutulamaz.
          Kişisel veri içeren kayıtlarınızın sınıflandırılması ve bunlara
          ilişkin saklama süreleri Veri Saklama ve İmha Politikası uyarınca
          belirlenir. İşlenme amaçları için gerekli süresi dolan veya veri
          sahibi olarak haklı talebiniz üzerine kişisel verileriniz, veri sahibi
          gerçek kişi tespit edilemeyecek şekilde anonimleştirilir veya silinir
          veya imha edilir. Ayrıntılı bilgi için lütfen Veri Saklama ve İmha
          Politikası’nı inceleyiniz. Haklarınız Defimec sizlere, kendi kişisel
          bilgilerinizin kullanım şeklini gözlemleme hakkı verir. Bilgilerinizi
          paylaşılmasına izin verdiğiniz ölçüde, üçüncü kişilerle paylaşabilir
          ve bu yetkiyi sonlandırma hakkınız baki olmak üzere tanıtım amaçlı
          e-postalar, bültenler, bilgilendirmeler gönderebilir. Kişisel
          verilerinizle ilgili olarak, KVKK madde 11 uyarınca sahip olduğunuz
          haklar aşağıda sayılmıştır: a) Kişisel verilerinizin işlenip
          işlenmediğini öğrenme, b) Kişisel verileriniz işlenmişse buna ilişkin
          bilgi talep etme, c) Kişisel verilerin işlenme amacını ve bunların
          amacına uygun kullanılıp kullanılmadığını öğrenme, d) Yurt içinde veya
          yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, e)
          Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
          düzeltilmesini isteme, f) 6698 Sayılı Kanun 7 maddede öngörülen
          şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini
          isteme, g) İşbu maddenin (d) ve (e) bentleri uyarınca yapılan
          işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere
          bildirilmesini isteme, h) İşlenen verilerin münhasıran otomatik
          sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi
          aleyhine bir sonucun ortaya çıkmasına itiraz etme, i) Kişisel
          verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranması
          hâlinde zararın giderilmesini talep etme. Bu çerçevede, söz konusu
          haklarınızı kullanmak için, kişisel kimlik bilgilerinizi ve yukarıda
          belirtilen haklardan hangisini kullanmak istediğinizi belirten talep
          mektubunuzu aşağıdaki adrese veya e-posta adresine gönderin: Adres:
          Barış Mah. Koşu Yolu Cad. Kuluçka Merkezi Blok No: 30 Gebze / Kocaeli
          E-posta: bilal.tekin@sportimec.com
        </div>
      </div>
    </Layout>
  );
}
