import { link } from "fs";

export default function JoinTheCommunity() {
  const socials = [
    {
      name: "facebook",
      image: "./images/Facebook.svg",
      link: "https://www.facebook.com/Sportimec-109029878276800",
    },
    {
      name: "twitter",
      image: "./images/Twitter.svg",
      link: "https://twitter.com/sport_imec",
    },
    {
      name: "instagram",
      image: "./images/Instagram.svg",
      link: "https://www.instagram.com/sport_imec",
    },
    {
      name: "telegram",
      image: "./images/Telegram.svg",
      link: "https://t.me/sport_imec",
    },
    {
      name: "linkedin",
      image: "./images/Linkedin.svg",
      link: "https://www.linkedin.com/company/sportimec",
    },
    {
      name: "youtube",
      image: "./images/Youtube.svg",
      link: "https://www.youtube.com/channel/UCtBlqrfka7gQ0RJOJd5TJNw",
    },
    {
      name: "discord",
      image: "./images/Discord.svg",
      link: "https://discord.gg/bttwbGEz99",
    },
    {
      name: "reddit",
      image: "./images/Reddit.svg",
      link: "https://www.reddit.com/r/sportimec/ ",
    },
    {
      name: "medium",
      image: "./images/Medium.svg",
      link: "https://medium.com/@defimec",
    },
  ];

  return (
    <div className="w-[820px] py-[32px] flex flex-col gap-[30px] items-center border-[#ACC0CE80] border-[1px] rounded-[10px] m-auto lgx:w-full lgx:gap-[25px] lgx:px-[32px]">
      <span className="text-[24px] leading-[32px] font-semibold tracking-[0.5px] text-[#6D7C89]">
        Join The Community
      </span>
      <div className="flex gap-[38px] items-center lgx:flex-wrap lgx:gap-y-[25px] lgx:justify-center">
        {socials.map(({ name, image, link }) => (
          <a href={link} key={name}>
            <img src={image} alt={name} />
          </a>
        ))}
      </div>
    </div>
  );
}
