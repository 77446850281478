import LetsTalkAbout from "../LetsTalkAbout";

export default function MobileContact() {
  return (
    <div id="contact" className="lg:hidden">
      <div className="flex flex-col gap-[25px]">
        <LetsTalkAbout />
        <div
          className="w-full flex flex-col items-center py-[74px] text-center"
          style={{
            backgroundImage: "url(./images/contactBackground.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <img
            src="./images/defimecApp.png"
            alt="Defimec App"
            className="w-[95px] h-[95px] mb-[21px]"
          />
          <p className="text-[32px] leading-[40px] tracking-[-0.5px] text-white mb-[15px]">
            Download <span className="font-semibold">Defimec App</span> to start
            trade on Sportimec now!
          </p>
          <p className="text-[14px] leading-[21px] font-light text-white mb-[15px]">
            Sportimec is an internal product of Defimec. Download the{" "}
            <span className="font-medium">Defimec App</span> from the buttons
            below to trade on Sportimec.
          </p>
          <div className="flex gap-[9px] items-center">
            <img
              src="./images/GooglePlay.png"
              alt="header image"
              className="w-[157px] h-[45px]"
            />
            <img
              src="./images/AppStore.png"
              alt="header image"
              className="w-[157px] h-[45px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
